import React from 'react';

import './styles.scss';
import { Links } from '../../../links';
import { ListItem } from './components/list-item';
import { ReferenceList, ReferenceListItem } from '../../models';

interface ListProps {
  data: ReferenceList;
}

const List: React.FC<ListProps> = ({ data }) => {
  return (
    <div className="list">
      <div className="list__content">
        {(data.fieldReferenceList || []).map(
          (item: ReferenceListItem, index: number) => (
            <ListItem key={index} question={item.title} />
          )
        )}
      </div>

      <div className="list__action">
        <Links modifier="arrow" fieldPageLink={data.fieldPageLink} />
      </div>
    </div>
  );
};

export { List };
