export interface CancelablePromiseLike<T> extends Promise<T> {
  cancel: () => void;
}

type CancelablePromiseExecutor<T> = (
  resolve: (value?: T | PromiseLike<T>) => void,
  reject: (reason?: any) => void,
  cancel?: () => void
) => void;

export class CancelablePromise<T = any>
  extends Promise<T>
  implements CancelablePromiseLike<T> {
  static resolve<T = any>(
    value?: T | CancelablePromise<T>
  ): CancelablePromise<T> {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return new CancelablePromise<T>((resolve, reject, cancel) => {
      return resolve(value);
    });
  }

  static reject<T = any>(reason?: any): CancelablePromise<T> {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return new CancelablePromise<T>((resolve, reject, cancel) => {
      return reject(reason);
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(executor: CancelablePromiseExecutor<T>) {
    super(executor);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-empty-function
  cancel() {}
}
