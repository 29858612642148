import React from 'react';
import { BlockGroupModel } from '../../models';
import './styles.scss';
import { ModifierProps, useModifier } from '@icrc/react-ui';
import { MobileContainer } from '../mobile-container';

interface BlockGroupProps extends ModifierProps {
  block: BlockGroupModel;
}

const BlockGroup: React.FC<BlockGroupProps> = ({
  block,
  children,
  modifier,
}) => {
  const stylesCN = useModifier('block-group', modifier);

  return (
    <div className={stylesCN}>
      <MobileContainer>
        <div className="page-container columns">
          <div className="column col-12 col-md-8 col-lg-12 col-xl-8">
            {block.fieldPreTitle && (
              <span
                className="block-group__pre_title"
                dangerouslySetInnerHTML={{ __html: block.fieldPreTitle }}
              />
            )}
            <br />
            <span
              className="block-group__title"
              dangerouslySetInnerHTML={{ __html: block.fieldGroupTitle }}
            />
          </div>
          <div className="column col-12 col-md-4 col-lg-12 col-xl-4" />
          {children}
        </div>
      </MobileContainer>
    </div>
  );
};

export { BlockGroup };
