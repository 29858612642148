import React, { useCallback } from 'react';
import { Button, Icon, Icons } from '@icrc/react-ui';

const Print: React.FC = () => {
  const print = useCallback(() => {
    window.print();
  }, []);

  return (
    <Button
      modifier="transparent no-padding"
      label={<Icon icon={Icons.Print} />}
      onClick={print}
    />
  );
};

export { Print };
