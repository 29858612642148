import { RestApi } from '../../../components';

export const createGlossaryAPI = ({ GET }: RestApi) => {
  const getGlossary = (): Promise<any> => {
        return GET(`/jsonapi/taxonomy_term/glossary`);
  };

  return {
    getGlossary
  };
};
