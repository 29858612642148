import React, { useCallback } from 'react';
import { TextModel } from '../../models';
import { ModifierProps, SocialSharing, useModifier, useTranslation } from '@icrc/react-ui';
import { useUrl } from '../../helper/url';
import { Glossary } from '../glossary';

import './styles.scss';
import { IconSRI, SriIcon } from '../../../icon-sri';

interface TextProps extends ModifierProps {
  text: TextModel;
}

const Text: React.FC<TextProps> = ({ text, modifier }) => {
  const { getImageUrlFromText } = useUrl();

  const injectHtml = useCallback(
    (html: string) => {
      return {
        __html: getImageUrlFromText(html),
      };
    },
    [getImageUrlFromText]
  );

  const stylesCN = useModifier('paragraph-text', modifier);
  const injectedHtml = injectHtml(text.fieldBody || '');
  const t = useTranslation();


  return (
    <div className={stylesCN}>
      <div className="paragraph-text__text_intro">
        {window.location.pathname !== '/about/support-relationships-armed-conflict/glossary' ? <Glossary text={injectedHtml.__html} modifier="" /> : <div dangerouslySetInnerHTML={injectedHtml}></div>}
      </div>
      <div className="social-media-sharing">
        <span className="social-media-sharing__text">{t('social-media.share-on')}</span>
        <SocialSharing link={window.location.href} type="twitter">
          <IconSRI icon={SriIcon.Twitter} />
        </SocialSharing>
        <SocialSharing link={window.location.href} type="facebook">
          <IconSRI icon={SriIcon.Facebook} />
        </SocialSharing>
        <SocialSharing link={window.location.href} type="linkedin">
          <IconSRI icon={SriIcon.Linkedin} />
        </SocialSharing>
      </div>
    </div>
  );
};

export { Text };
