import { api } from '../../../../../../services';
import { JsonApiParsedResponse } from '../../../../../../services/api/models/jsonApiParsed.response';
import { FieldPageLink } from '../../../../models';

export interface AllFilters {
  sideOfRelationship: FilterOption[];
  actor: FilterOption[];
  activities: FilterOption[];
  support: FilterOption[];
  protectionConcern: FilterOption[];
  areaOfPracticalMeasures: FilterOption[];
  keyword: FilterOption[];
}

export interface FilterOption {
  value: number;
  description: string;
}

export const getAllFilters = (): Promise<AllFilters> => {
  const promises = [
    api.practicalQuestions.getTaxonomy('side_of_relationship'),
    api.practicalQuestions.getTaxonomy('actor'),
    api.practicalQuestions.getTaxonomy('activities'),
    api.practicalQuestions.getTaxonomy('support'),
    api.practicalQuestions.getTaxonomy('protection_concern'),
    api.practicalQuestions.getTaxonomy('practical_measures'),
  ];
  return Promise.all(promises).then((res) => {
    return {
      sideOfRelationship: parseToFilterOptions(res[0]),
      actor: parseToFilterOptions(res[1]),
      activities: parseToFilterOptions(res[2]),
      support: parseToFilterOptions(res[3]),
      protectionConcern: parseToFilterOptions(res[4]),
      areaOfPracticalMeasures: parseToFilterOptions(res[5]),
      keyword: [{ value: 0, description: '' }],
    };
  });
};

export const getQuestions = (
  langCode: string,
  filters?: any,
  offset = 0,
  defaultPage = 10
) => {
  return api.practicalQuestions
    .getQuestionResults(
      langCode,
      buildQuestionQuery(filters),
      offset,
      defaultPage
    )
    .then((res: any) => parseQuestionsResult(res));
};

const mappingQuestions = (question: any) => ({
  title: question.title,
  tags: parseTags(question),
  id: question.drupalInternalNid,
});
const parseQuestionsResult = (
  questionsResponseFromServer: JsonApiParsedResponse
) => {
  if (
    !questionsResponseFromServer.data ||
    !questionsResponseFromServer.data.length
  ) {
    return {
      questions: [],
      total: 0,
    };
  }
  return {
    questions: questionsResponseFromServer.data
      .filter((q: any) => !q.fieldHighlight)
      .map(mappingQuestions),
    keyQuestions: questionsResponseFromServer.data
      .filter((q: any) => q.fieldHighlight)
      .map(mappingQuestions),
    total: questionsResponseFromServer.meta?.count,
  };
};

const parseTags = (question: any): FieldPageLink[] => {
  return [
    {
      title: question.fieldAreaOfPracticalMeasure?.name,
      url: question.fieldAreaOfPracticalMeasure?.fieldDetailPage?.fieldPath,
    },
    {
      title: question.fieldCategoryOfSupport?.name,
      url: question.fieldCategoryOfSupport?.fieldDetailPage?.fieldPath,
    },
    {
      title: question.fieldProtectionConcern?.name,
      url: question.fieldProtectionConcern?.fieldDetailPage?.fieldPath,
    },
  ].filter((s) => s.title);
};

const buildQuestionQuery = (filters?: any) => {
  if (!filters) {
    return '';
  }

  return Object.keys(filters)
    .filter(
      (key) => filters[key] !== 0 && filters[key] !== '0' && filters[key] !== ''
    )
    .map(
      (key) =>
        `${
          isNaN(filters[key])
            ? `&filter[title][condition][path]=title&filter[title][condition][operator]=CONTAINS&filter[title][condition][value`
            : `filter[${key}.drupal_internal__tid`
        }]=${filters[key]}`
    )

    .join('&');
};

export const DEFAULT_FILTER_OPTION = {
  description: 'All',
  value: 0,
};

const parseToFilterOptions = (
  filterFromServer: JsonApiParsedResponse
): FilterOption[] => {
  if (filterFromServer.data && filterFromServer.data.length) {
    const options = filterFromServer.data.map((f: any) =>
      parseToFilterOption(f)
    );
    options.unshift(DEFAULT_FILTER_OPTION);
    return options;
  }
  return [DEFAULT_FILTER_OPTION];
};

const parseToFilterOption = (filterFromServer: any): FilterOption => {
  return {
    value: filterFromServer.drupalInternalTid,
    description: filterFromServer.name,
  };
};
