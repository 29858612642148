export const dateFormater = (el: any) => {
  const startDate = el;
  const newStartDate = new Date(startDate);

  const finalStartDate =
    newStartDate.toLocaleString('en', { month: 'long' }) +
    ' ' +
    newStartDate.getDate() +
    ', ' +
    newStartDate.getFullYear();
  return finalStartDate;
};

function padTo2Digits(num: any) {
  return String(num).padStart(2, '0');
}

export const dateAndTimeFormatter = (el: any) => {
  const startDate = el;
  const newStartDate = new Date(startDate);

  const finalStartDate =
  newStartDate.getDate() +
    ' ' +
  newStartDate.toLocaleString('en', { month: 'long' }) +
    ' ' +
    newStartDate.getFullYear() + 
    ' '  + 
    newStartDate.getHours() + 
    ':' + 
    padTo2Digits(newStartDate.getMinutes());
  return finalStartDate;
}