import { SlideMenu } from '../models/slide-menu';

const createAnchor = (title: string | null, index: number) => {
  return encodeURI(title || '')
    .split('%A0')
    .join('')
    .split('%C2')
    .join('')
    .split('%20')
    .join('')
    .split('.')
    .join('');
};

const extractContentSlider = () => {
  const results: SlideMenu[] = [];
  document.querySelectorAll('h2,h3').forEach((selector, key) => {
    const anchor = createAnchor(selector.textContent, key);
    selector.id = anchor;

    if (selector.nodeName === 'H2') {
      results.push({
        title: selector.textContent,
        anchor: anchor,
        subItem: [],
      });
    } else if (selector.nodeName === 'H3') {
      if (results.length === 0) {
        results.push({
          title: '',
          anchor: anchor,
          subItem: [],
        });
      }
      results[results.length - 1]?.subItem.push({
        title: selector.textContent,
        anchor: anchor,
        subItem: [],
      });
    }
  });
  return results;
};

export { extractContentSlider };
