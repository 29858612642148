import React from 'react';
import { ModifierProps, useModifier } from '@icrc/react-ui';
import './styles.scss';

interface ImagePositionProps extends ModifierProps {
  img: string;
  maxHeight?: number;
}

const ImagePosition: React.FC<ImagePositionProps> = ({
  modifier,
  children,
  img,
  maxHeight,
}) => {
  const stylesCN = useModifier('image-position', modifier);

  return (
    <div className={stylesCN}>
      <div className="columns image-position__content">
        <div className="column col-12 col-md-3 image-position__content_image">
          <img src={img} style={{ maxHeight: maxHeight }} alt="ICRC" />
        </div>
        <div className="column col-12 col-md-9">{children}</div>
      </div>
    </div>
  );
};

export { ImagePosition };
