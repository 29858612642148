import React, { useCallback } from 'react';
import { ImagePosition } from './components/image-position';
import { BlockGroup } from './components/block-group';
import { PaperGroupItem } from './components/paper-group-item';
import { Grid } from './components/grid';
import { Hero } from './components/hero';
import { Text } from './components/text';
import { Steps } from './components/steps';
import { CarouselContainer } from './components/carousel-container';
import { List } from './components/list';
import { MobileContainer } from './components/mobile-container';
import { PracticalQuestionsList } from './components/practical-questions-list';
import { PracticalQuestionsContext } from '../../pages/context';
import { DEFAULT_PRACTICAL_QUESTIONS } from './models';
import { HyperlinkContent } from './components/hyperlink-content';
import { Resource } from './components/resource';
import { Breadcrumb } from './components/breadcrumb';
import { BreadcrumbModel } from './models/breadcrumb';
import { TableContent } from './components/table-content';
import { useUrl } from './helper/url';
import { Spacer } from './components/spacer';
import { EventsList } from './components/events-list';
import { EventDetails } from './components/event-details';
import { FootnotesTooltip } from './components/footnotes-tooltip';
import { VideoContent } from './components/video-content';

interface ComponentLoaderProps {
  type?:
    | 'node--landing_page'
    | 'paragraph--block_group'
    | 'paragraph--hero'
    | 'paragraph--text';
  data?: any;
  breadcrumb?: BreadcrumbModel[];
}

const ComponentLoader: React.FC<ComponentLoaderProps> = ({
  type = 'node--landing_page',
  data,
  breadcrumb,
}) => {
  const { getUrl } = useUrl();

  const createBlockGroup = useCallback(
    (data) => {
      switch (data.fieldDisplayType) {
        case 'paper':
          return data.fieldGroupMedia?.fieldMediaOembedVideo ? (
            <BlockGroup block={data} modifier="video">
              <VideoContent
                title={data.fieldGroupMedia?.name}
                dataVideo={data.fieldGroupMedia?.fieldMediaOembedVideo}
              />
              {/* <div className="column col-12 col-sm-6"> */}
              <div className="video-content-right-side">
                <PaperGroupItem
                  block={data}
                  modifier="video"
                  layoutModifier="video-content-right-side"
                />
              </div>
            </BlockGroup>
          ) : (
            <div className="page-container">
              <ImagePosition
                modifier="right no-mobile align-with-title"
                img={getUrl(data.fieldGroupMedia?.fieldMediaImage?.uri)}
                maxHeight={400}
              >
                <BlockGroup block={data}>
                  <PaperGroupItem block={data} />
                </BlockGroup>
              </ImagePosition>
            </div>
          );

        case 'grid':
          return (
            <BlockGroup block={data} modifier="grid">
              <div className="page-container">
                <Grid block={data} />
              </div>
            </BlockGroup>
          );

        case 'step_notes':
          return (
            <div className="page-container">
              <MobileContainer>
                <Steps data={data} modifier="with-notes" />
              </MobileContainer>
            </div>
          );

        case 'steps':
          return (
            <div className="page-container">
              <MobileContainer>
                <Steps data={data} modifier="no-margin grey" />
              </MobileContainer>
            </div>
          );

        case 'carousel':
          return (
            <BlockGroup block={data}>
              <ImagePosition
                modifier="left"
                img={getUrl(data.fieldGroupMedia?.fieldMediaImage?.uri)}
              >
                <CarouselContainer block={data} />
              </ImagePosition>
            </BlockGroup>
          );

        default:
          return <div>Not a field display type - {data.fieldDisplayType}</div>;
      }
    },
    [getUrl]
  );

  const createComponent = useCallback(
    (data, type, breadcrumb) => {
      switch (type) {
        case 'node--landing_page':
          return (
            <div>
              <Breadcrumb breadcrumb={breadcrumb} />
              <div id="main-content">
                {data &&
                  data.fieldGroup?.map((field: any, i: number) => (
                    <>
                      <FootnotesTooltip />
                      <ComponentLoader type={field.type} data={field} key={i} />
                    </>
                  ))}
              </div>
            </div>
          );

        case 'paragraph--block_group':
          return createBlockGroup(data);

        case 'paragraph--hero':
          return <Hero hero={data} />;

        case 'paragraph--text':
          return (
            <div className="page-container">
              <MobileContainer>
                <Text text={data} modifier="align-right" />
              </MobileContainer>
            </div>
          );
        case 'paragraph--reference_list':
          return (
            <div className="page-container">
              <ImagePosition
                modifier="left no-mobile center"
                img={getUrl(data.fieldGroupMedia?.fieldMediaImage?.uri)}
                maxHeight={550}
              >
                <BlockGroup block={data} modifier="list">
                  <List data={data} />
                </BlockGroup>
              </ImagePosition>
            </div>
          );

        case 'paragraph--practical_questions_list':
          return (
            <PracticalQuestionsContext.Store
              state={DEFAULT_PRACTICAL_QUESTIONS}
            >
              <PracticalQuestionsList
                img={getUrl(data.fieldGroupMedia?.fieldMediaImage?.uri)}
                data={data}
              />
            </PracticalQuestionsContext.Store>
          );

        case 'paragraph--events_list':
          return <EventsList />;

        case 'paragraph--event':
          return <EventDetails />;

        case 'paragraph--hyperlink_content':
          return <HyperlinkContent data={data} />;

        case 'paragraph--resource':
          return (
            <div className="page-container">
              <Resource data={data} />
            </div>
          );

        case 'paragraph--table_of_contents':
          return <TableContent data={data} />;

        case 'paragraph--spacer':
          return <Spacer height={data.fieldSize} />;

        default:
          return <div>Not a Type known - {type}</div>;
      }
    },
    [createBlockGroup, getUrl]
  );

  return createComponent(data, type, breadcrumb);
};

export { ComponentLoader };
