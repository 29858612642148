import React from 'react';
import { Icon, Icons, ModifierProps } from '@icrc/react-ui';
import './styles.scss';
import { BreadcrumbModel } from '../../models/breadcrumb';

interface BreadcrumbProps extends ModifierProps {
  breadcrumb?: BreadcrumbModel[];
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ breadcrumb }) => {
  return (
    <div className="breadcrumb">
      <div className="page-container breadcrumb__content">
        {breadcrumb?.map((b, index) => (
          <div key={index} className="breadcrumb__item">
            <a href={b.link}>{b.title}</a>
            {index !== breadcrumb?.length - 1 ? (
              <Icon icon={Icons.ChevronRight} />
            ) : null}
          </div>
        ))}
      </div>
    </div>
  );
};

export { Breadcrumb };
