import { mergeState, Reducer } from '@icrc/react-ui';

const contextReducer: Reducer = (state, { type, ...values }) => {
  console.log('contextReducer', { state, type, values });
  switch (type) {
    case 'update':
      return { ...mergeState(state, values) };
    case 'reset':
      return { ...values.resetValue } || {};
    case 'loading':
      if (values && values.loading) {
        return {
          ...state,
          loading: true,
        };
      }
      if (state.loading) {
        delete state['loading'];
        return { ...state };
      }
  }
  return state;
};

export { contextReducer };
