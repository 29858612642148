import { fetchApi, RestApi } from '../../components';
import { createGlossaryAPI } from './services/glossary';
import { createEventsApi } from './services/events-list';
import { createLayoutApi } from './services/layout';
import { createPracticalQuestionsAPI } from './services/practical-questions-filters';

function createApi(api: RestApi) {
  return {
    layout: createLayoutApi(api),
    practicalQuestions: createPracticalQuestionsAPI(api),
    eventsList: createEventsApi(api),
    glossary: createGlossaryAPI(api),
  };
}

const api = createApi(fetchApi);

export { api };
