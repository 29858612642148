import React from 'react';
import { ModifierProps, useModifier } from '@icrc/react-ui';
import { SriIcon } from './sri-icons';
import './styles.scss';

export { SriIcon } from './sri-icons';

export interface IconProps extends ModifierProps {
  icon: SriIcon;
}

const IconSRI: React.FC<IconProps> = ({ modifier, icon }) => {
  const iconCN = useModifier('sri-icon', modifier, `sri-icon-${icon}`);
  return <i className={iconCN} />;
};

export { IconSRI };
