import React, {
  KeyboardEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import './styles.scss';
import {
  Button,
  Icon,
  Icons,
  Input,
  InputEvent,
  useModifier,
} from '@icrc/react-ui';
import { useOutsideClick } from './hook/outside-click';
import { SearchResults } from './components/search-results';
import { MobileContainer } from '../../../component-loader/components/mobile-container';
import { api } from '../../../../services';
import { useTranslationLanguage } from '@icrc/react-ui';

interface SearchProps {
  setShowMenu: (show: boolean) => void; //TODO: Search should not be responsible to close Menu. Menu itself should be close when clicked outside.
}

const Search: React.FC<SearchProps> = ({
  setShowMenu,
}) => {
  const [isExpanded, setExpanded] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);
  const [search, dispatchSearch] = useState('');
  const [results, dispatchResults] = useState<any[]>([]);
  const contentRef = useRef<HTMLInputElement>(null);
  const body = document.getElementsByTagName('body')[0];
  const [{ languageTag }] = useTranslationLanguage();

  const stylesCN = useModifier('search', '', {
    'search--expanded': isExpanded,
  });
  useEffect(() => {
    if (isExpanded) {
      setShowMenu(false);
      body.classList.add('js-scroll-disabled');
    }
    else {
      body.classList.remove('js-scroll-disabled');
    }
  }, [isExpanded, setShowMenu]);

  const onClickExpand = useCallback(() => {
    setExpanded(!isExpanded);
  }, [isExpanded]);

  const closeSearch = useCallback(() => {
    setExpanded(false);
  }, []);

  useOutsideClick(contentRef, closeSearch);

  const handleOnSearchChange = useCallback((ev: InputEvent) => {
    dispatchSearch(ev.value);
  }, []);

  const onClickSearch = useCallback(
    (word: string) => () => {
      if (word.length < 3) return;
      api.layout.search(languageTag, word).then((res) => {
        setHasSearched(true);
        dispatchResults(
          res.data.map((item: any) => ({
            title: item.title,
            description:
              (item?.fieldGroup || []).find(
                (fieldGroupItem: any) => fieldGroupItem.fieldBody !== undefined
              )?.fieldBody || '',
            url: item.fieldPath,
          }))
        );
      });
    },
    [languageTag]
  );

  const handleKeyUp = useCallback(
    (ev: KeyboardEvent) => {
      if (ev.key === 'Enter') {
        onClickSearch(search)();
      }
    },
    [onClickSearch, search]
  );

  return (
    <div className={stylesCN} ref={contentRef}>
      <div className="search__action_content">
        <div className="search__action" onClick={onClickExpand}>
          <Icon icon={isExpanded ? Icons.Close : Icons.Magnifier} />
        </div>
      </div>
      <div className={`search__content ${hasSearched ? 'search__content--searched' : ''}`}>
        <MobileContainer>
          <div className="page-container">
            <div className="columns">
              <div className="column col-grow search__content_item">
                <Input
                  type="text"
                  value={search}
                  onChange={handleOnSearchChange}
                  onKeyUp={handleKeyUp}
                />
              </div>
              <div className="column col-auto search__content_item">
                <Button
                  modifier="primary"
                  onClick={onClickSearch(search)}
                  label={
                    <div>
                      <span className="search__content_item__desktop">
                        SEARCH
                      </span>
                      <div className="search__content_item__mobile">
                        <Icon icon={Icons.Magnifier} />
                      </div>
                    </div>
                  }
                />
              </div>
            </div>
            <SearchResults
              results={results}
              hasSearched={hasSearched}
              search={search}
            />
          </div>
        </MobileContainer>
      </div>
    </div>
  );
};

export { Search };
