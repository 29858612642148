import React, { useCallback, useState, useEffect } from 'react';
import './styles.scss';

const createImageModalManager = (): [
    React.FC,
    any
] => {
    let checkImageEnlargeEvent: undefined | (() => void);

    const imageModal = {
        atatchClickEvent: () => {
            if (checkImageEnlargeEvent) {
                checkImageEnlargeEvent();
            }
        },
    };

    const ImageModalContainer: React.FC = () => {
        const [modal, setModal] = useState<any>(undefined);
        const hideModal = () => {
            setModal(undefined);
        }

        checkImageEnlargeEvent = useCallback(() => {
            setTimeout(() => {
                const imageTest = document.querySelectorAll('img');
                if (imageTest && imageTest.length > 1) {
                    imageTest.forEach(image => {
                        if (image.className.indexOf('modal-click-enlarger') < 0 && image.className.indexOf('no-popup')) {
                            image.classList.add('modal-click-enlarger');
                            image.addEventListener('click', () => {
                                setModal(image.src)
                            });
                        }
                    })
                }
            }, 1)
        }, []
        );

        useEffect(checkImageEnlargeEvent, [checkImageEnlargeEvent])

        if (modal) {
            return (
                <div className="new-modal">
                    <div className="overlay" onClick={hideModal}></div>
                    <div className="modal-content">
                        <img src={modal} alt="" />
                    </div>
                </div>
            );
        }

        return null;
    };

    return [ImageModalContainer, imageModal];
};

export { createImageModalManager };
