import React from 'react';
import { ModifierProps, useModifier } from '@icrc/react-ui';
import { BlockGroupModel } from '../../models';
import './styles.scss';
import { GroupItem } from '../group-item';

interface PaperGroupItemProps extends ModifierProps {
  block: BlockGroupModel;
  layoutModifier?: string;
}

const PaperGroupItem: React.FC<PaperGroupItemProps> = ({
  block,
  modifier,
  layoutModifier,
}) => {
  const stylesCN = useModifier('paper-group-item', modifier);
  const layoutClass = layoutModifier
    ? layoutModifier
    : 'column col-12 col-sm-6';
  return (
    <div className={stylesCN}>
      <div className="columns">
        {block.fieldGroupItem?.map((item, index) => (
          <div className={layoutClass} key={index}>
            <GroupItem item={item} modifier="white" />
          </div>
        ))}
      </div>
    </div>
  );
};

export { PaperGroupItem };
