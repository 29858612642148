import React from 'react';
import { TopMenu } from './components/top-menu';
import './styles.scss';

const Header: React.FC<any> = ({ children }) => {
	return (
		<div className="header">
			<div className="header__content">
				<TopMenu />
				{children ? <div>{children}</div> : null}
			</div>
		</div>
	);
};

export { Header };
