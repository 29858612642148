import { CancelablePromise } from './extensions';
import { parseResponse, query } from './helpers';
import { getSession, setSession } from './session';
import { ApiMethod } from '../api';
import fetch from './fetch';
import { store } from '../store';
import { API_JWT_TOKEN, SRI_LNDO_SITE } from '../../../config-envs';

export type { ErrorResponse } from './helpers/parse-response';

const API = {
  URL: process.env.REACT_APP_API_URL || SRI_LNDO_SITE,
};

async function getToken() {
  if (store.get('token')) {
    return Promise.resolve(store.get('token'));
  }
  if (API_JWT_TOKEN !== undefined) {
    return fetch(`${API.URL}/jwt/token`, {
      headers: {
        Authorization: `Basic ${window.btoa(API_JWT_TOKEN)}`,
      },
    })
      .then((response) => {
        return parseResponse(response);
      })
      .then((response) => {
        store.set('token', response);
        return response;
      });
  }
}

async function call(
  url: string,
  params: any = {},
  tries: number = 3
): Promise<any> {
  const session = await getToken();
  const authorization = `Bearer ${session.token}`;

  return fetch(`${API.URL}${url}${query.stringify(params.query)}`, {
    ...params,
    headers: {
      ...params.headers,
      'Content-Type': 'application/json',
      authorization: authorization,
    },
  })
    .then((response) => {
      if (response.status === 403 && tries > 0) {
        store.set('token', '');
        return call(url, params, tries - 1);
      }
      return parseResponse(response);
    })
    .catch(() => {
      store.set('token', '');
    });
}

const DELETE: ApiMethod = (method, params = {}) => {
  return call(method, {
    method: 'DELETE',
    ...params,
  });
};

const PUT: ApiMethod = (method, params = {}) => {
  return call(method, {
    method: 'PUT',
    ...params,
    body: JSON.stringify(params.body),
  });
};

const POST: ApiMethod = (method, params = {}) => {
  return call(method, {
    method: 'POST',
    ...params,
    body: JSON.stringify(params.body),
  });
};

const GET: ApiMethod = (method, params = {}) => {
  return call(method, { method: 'GET', ...params });
};

const PATCH: ApiMethod = (method, params = {}) => {
  return call(method, { method: 'PATCH', ...params });
};

const UPLOAD_FILE = (url: string, params: any) => {
  const form = new FormData();
  Object.keys(params.body).forEach((key) => {
    form.append(key, params.body[key]);
  });
  return fetch(`${API.URL}${url}${query.stringify(params.query)}`, {
    method: 'POST',
    body: form,
    headers: {
      ...params.headers,
    },
  }).then((response) => {
    return parseResponse(response);
  });
};

const MOCK: ApiMethod = (method, params = {}) => {
  console.log('MOCK', { method, params });
  if (method) {
    const init: any = {
      ...params,
      method: 'GET',
      headers: {
        ...params.headers,
        'Content-Type': 'application/json',
      },
    };
    return fetch(`${method}${query.stringify(params.query)}`, init);
  }
  return new CancelablePromise<any>((resolve) => {
    setTimeout(() => {
      resolve(params.body || {});
    }, params.timeout || 2500);
  });
};

export const fetchApi = {
  getSession,
  setSession,
  POST,
  GET,
  PUT,
  PATCH,
  DELETE,
  MOCK,
  UPLOAD_FILE,
};
