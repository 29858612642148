import React from 'react';
import { PracticalQuestionsFilter } from './components/practical-questions-filter';
import { Icon, Icons, SocialSharing } from '@icrc/react-ui';
import { PracticalQuestionsResults } from './components/practical-questions-results';
import { Print } from '../../../print';

import './styles.scss';

interface PracticalQuestionsListProps {
  img?: string;
  data?: any;
}
const PracticalQuestionsList: React.FC<PracticalQuestionsListProps> = ({
  img,
  data,
}) => {
  return (
    <div className="page-container">
      <div className="practical-questions-list">
        <div className="practical-questions-list__top">
          <span className="practical-questions-list__top-title">
            {data?.fieldGroupTitle}
          </span>
          <div className="practical-questions-list__top-action">
            <SocialSharing type="mail" link={window.location.href}>
              <Icon icon={Icons.Email} />
            </SocialSharing>
            <Print />
          </div>
        </div>

        <PracticalQuestionsFilter />

        <PracticalQuestionsResults img={img} />
      </div>
    </div>
  );
};

export { PracticalQuestionsList };
