import React, { useEffect } from 'react';
import './styles.scss';

export const FootnotesTooltip: React.FC = () => {
  const isInViewport = (element: Element) => {
    const rect = element?.getBoundingClientRect();
    return rect?.left >= 0;
  };

  useEffect(() => {
    let x = document.getElementsByTagName('FN');
    if (x) {
      for (let i = 0; i < x.length; i++) {
        if (x[i]) {
          const newItem = document.createElement('span');
          newItem.innerHTML = `<div class="tooltip"><div class="tooltip-icon">${
            i + 1
          }
            <span class="tooltip-text left">${
              x[i].textContent
                ?.trim()
                .split('\n')
                .filter((col) => col !== '' && col !== '\n') !== undefined &&
              x[i]
                ?.textContent!.trim()
                .split('\n')
                .filter((col) => col !== '' && col !== '\n').length > 1
                ? x[i].textContent
                    ?.trim()
                    .split('\n')
                    .filter((col) => col !== '' && col !== '\n')
                    .slice(1)
                    .join('')
                : x[i].textContent
            }</span>
          </div></div>`;

          x[i].innerHTML = newItem.innerHTML;

          if (
            !isInViewport(x[i].getElementsByClassName('tooltip-text left')[0])
          ) {
            x[i].innerHTML = x[i].innerHTML.replace('left', 'right');
          }
        }
      }
    }
  }, []);
  return <></>;
};
