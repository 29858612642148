import {
  HierarchyElement,
  JsonApiResponse,
} from '../../services/api/models/jsonApi.response';
import { JsonApiTree } from './models/json-api-tree';

const customDeserializer = (result: JsonApiResponse) => {
  const items = result.meta.hierarchy || [];
  return buildItems(items, result);
};

const buildItems = (
  items: HierarchyElement[],
  result: JsonApiResponse
): JsonApiTree[] => {
  return items.map((item) => {
    return buildItem(item, result);
  });
};

const buildItem = (
  item: HierarchyElement,
  result: JsonApiResponse
): JsonApiTree => {
  const subItems = [];
  if (item.children && item.children.length > 0) {
    subItems.push(...buildItems(item.children, result));
  }
  const key = item.uuid;
  return { ...search(key, result), subItems } as JsonApiTree;
};

const search = (key: string, result: JsonApiResponse) => {
  let search = result.data.find((data) => data.id === key);
  if (search) {
    search = { ...search, ...search.attributes };
    delete search.attributes;
  }
  return search;
};

export { customDeserializer };
