import React from 'react';
import './styles.scss';
import { FieldPageLink } from '../../../../models';

export interface ListItemProps {
  question: string;
  tags?: FieldPageLink[];
}

const ListItem: React.FC<ListItemProps> = ({ question, tags }) => {
  return (
    <div className="list-item">
      <div className="list-item__tags">
        <ul>
          {(tags || []).map((tag: FieldPageLink, index: number) =>
            tag.url ? (
              <li key={index}>
                <a href={tag.url}>{tag.title}</a>
              </li>
            ) : (
              <li key={index}>{tag.title}</li>
            )
          )}
        </ul>
      </div>
      <div className="list-item__question">{question}</div>
    </div>
  );
};

export { ListItem };
