type ExtendedUrl = URL & { query?: { [key: string]: string } };

function parseQueryString(query?: string): ExtendedUrl | {} {
  if (query) {
    const q: any = {};
    const qs = query.split('&');
    for (const i in qs) {
      if (qs[i]) {
        const qq = String(qs[i]).split('=');
        if (qq && qq.length === 2) {
          q[qq[0]] = decodeURIComponent(qq[1]);
        }
      }
    }
    return q;
  }
  return {};
}

function stringifyQueryString(query?: { [key: string]: string }) {
  if (query) {
    return Object.keys(query)
      .map((key: string) => `${key}=${encodeURIComponent(query[key])}`)
      .join('&');
  }
  return '';
}

function parseUrl(url: string) {
  const u: any = new URL(url);
  u.query = parseQueryString(u.search.split('?')[1]);
  return u;
}

function stringifyUrl(url: ExtendedUrl) {
  return `${url.pathname}?${stringifyQueryString(url.query)}`;
}

export const url = {
  parse: parseUrl,
  stringify: stringifyUrl,
};
