import { useTranslation } from '@icrc/react-ui';
import React, { useEffect, useState } from 'react';
import { api } from '../../../../services';
import { Links } from '../../../links';
import { dateFormater } from './helper/dateFormater';
import './styles.scss';

interface EventsListProps {
  modifier?: string;
}

const EventsList: React.FC<EventsListProps> = ({ modifier }) => {
  const [eventsList, setEventsList] = useState([]);
  const [totalEvents, setTotalEvents] = useState(0);
  const [loadMoreCount, setLoadMoreCount] = useState(1);
  const t = useTranslation();

  useEffect(() => {
    api.eventsList.getEventsList().then((resp) => {
      if (resp?.data[0]) {
        setEventsList(resp?.data);
        setTotalEvents(resp?.meta.count);
      }
    });
  }, [modifier]);

  const loadMoreHandler = () => {
    api.eventsList.getMoreEvents(loadMoreCount).then((resp) => {
      if (resp?.data[0]) {
        setEventsList(eventsList.concat(resp?.data));
        setLoadMoreCount(loadMoreCount + 1);
      }
    });
  };

  const dateChecker = (el: any) => {
    const date = new Date(el);
    if (date > new Date()) {
      return true;
    } else return false;
  };

  return (
    <div className="page-container columns">
      <div className="column col-12">
        <div className=" events-list">
          {eventsList.map((event: any, index) => {
            const eventLink = {
              title: t('events.read-more'),
              url: `${event.parentId}`,
            };
            return (
              <div className="events-list__item" key={index}>
                <div className="events-list__content">
                  <div className="events-list__date-container">
                    <div
                      className={`events-list__ribbon ${
                        dateChecker(event.fieldDateTime?.value)
                          ? ''
                          : 'events-list__ribbon--past'
                      }`}
                    >
                      {dateChecker(event.fieldDateTime?.value)
                        ? t('events.upcoming-events')
                        : t('events.past-events')}
                    </div>
                    <div className="events-list__date">
                      {dateFormater(event.fieldDateTime?.value)}
                    </div>
                  </div>
                  <div
                    className="events-list__title"
                    dangerouslySetInnerHTML={{ __html: event?.fieldEventName }}
                  />

                  <div
                    className="events-list__text"
                    dangerouslySetInnerHTML={{
                      __html: event?.fieldEventDescription.value,
                    }}
                  ></div>
                  <Links fieldPageLink={eventLink} modifier="arrow" />
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="events-list__wrapper column col-12">
        {modifier === 'short' ? (
          <a href="/en/events" className="events-list__button">
            {t('events.all-events')}
          </a>
        ) : totalEvents > eventsList.length ? (
          <button className="events-list__button" onClick={loadMoreHandler}>
            {t('events.more-events')}
          </button>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export { EventsList };
