import React from 'react';
import { ModifierProps } from '@icrc/react-ui';

import './styles.scss';
import { BlockGroupModel } from '../../models';

interface HyperlinkContentProps extends ModifierProps {
  data: BlockGroupModel;
}

const HyperlinkContent: React.FC<HyperlinkContentProps> = ({ data }) => {
  return (
    <div className="page-container">
      <div className="hyperlink-content">
        {data.fieldGroupItem.map((l, number) => (
          <div className="hyperlink-content__item" key={number}>
            <div className="hyperlink-content__item-title">
              {l.fieldItemTitle}
            </div>
            <div
              className="hyperlink-content__item-text"
              dangerouslySetInnerHTML={{ __html: l.fieldItemText }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export { HyperlinkContent };
