import React, { useCallback, useEffect } from 'react';
import {
  Pagination,
  useTranslation,
  useTranslationLanguage,
} from '@icrc/react-ui';
import { url } from '../../helper/url';
import { usePracticalQuestions } from '../../hooks/practical-questions';
import { ListItem } from '../../../list/components/list-item';

import './styles.scss';

interface PracticalQuestionsResultsProps {
  img?: string;
}

const PracticalQuestionsResults: React.FC<PracticalQuestionsResultsProps> = ({
  img,
}) => {
  const {
    dispatch,
    practicalQuestions,
    getPracticalQuestions,
  } = usePracticalQuestions();
  const [{ languageTag }] = useTranslationLanguage();
  const t = useTranslation();

  useEffect(() => {
    getPracticalQuestions(0, url.parse(window.location.toString()).query);
  }, [dispatch, languageTag, getPracticalQuestions]);

  const handleClickPage = useCallback(
    (page: number) => getPracticalQuestions(page, practicalQuestions.filters),
    [getPracticalQuestions, practicalQuestions.filters]
  );

  return (
    <div className="practical-questions-results">
      <div className="columns">
        <div className="column col-sm-3 practical-questions-results__no-mobile">
          <img src={img} alt="Practical Questions" />
        </div>
        <div className="column col-12 col-sm-9">
          <div className="practical-questions-results__total practical-questions-results__no-mobile">
            {t('practical-question.filters.showing')}{' '}
            <b>{practicalQuestions.total}</b>{' '}
            {t('practical-question.filters.results')}
          </div>
          {practicalQuestions.keyQuestions?.length > 0 ? (
            <div className="practical-questions-results__key-questions">
              <div className="practical-questions-results__key-questions-title">
                {t('practical-question.key-questions')}
              </div>
              {practicalQuestions.keyQuestions.map((q: any, index: number) => (
                <ListItem key={index} question={q.title} tags={q.tags} />
              ))}
            </div>
          ) : null}

          {practicalQuestions.questions?.map((q: any, index: number) => (
            <ListItem key={index} question={q.title} tags={q.tags} />
          ))}
        </div>
      </div>
      <div className="columns">
        <div className="column col-12 col-sm-3" />
        <div className="column col-12 col-sm-9 ">
          {practicalQuestions.total !== 0 ? (
            <div className="practical-questions-results__pagination">
              <Pagination
                handleClickPage={handleClickPage}
                count={Math.ceil(practicalQuestions.total / 10)}
                currentPage={practicalQuestions.currentPage}
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export { PracticalQuestionsResults };
