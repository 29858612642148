import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Glossary } from '../glossary';
import { SlideMenuRefProps, SliderMenu } from '../slider-menu';
import { SlideMenuContent } from './components/slide-menu-content';

import './styles.scss';

interface TableContentProps {
  data: any;
}

const TableContent: React.FC<TableContentProps> = ({ data }) => {
  const slideMenuRef = useRef<SlideMenuRefProps>(null);
  const [clickableMenuVisible, setClickableMenuVisible] = useState<boolean>(
    true
  );

  const handleSlider = useCallback(
    (isToClose: boolean) => () => {
      if (slideMenuRef.current) {
        if (isToClose) {
          slideMenuRef.current.hide();
          setClickableMenuVisible(true);
        } else {
          slideMenuRef.current.show();
          setClickableMenuVisible(false);
        }
      }
    },
    []
  );

  return (
    <div className="table-content">
      <div className="page-container">
        <div className="slider-menu-desktop">
          <SliderMenu
            ref={slideMenuRef}
            handleSlider={handleSlider}
            isClickableBarVisible={clickableMenuVisible}
          >
            <SlideMenuContent
              handleSlider={handleSlider}
              fieldPageLink={data.fieldPageLink}
              expanded={true}
            />
          </SliderMenu>
        </div>
        <div className="slider-menu-desktop--hidden">
          <SliderMenu
            ref={slideMenuRef}
            handleSlider={handleSlider}
            isClickableBarVisible={clickableMenuVisible}
          >
            <SlideMenuContent
              handleSlider={handleSlider}
              fieldPageLink={data.fieldPageLink}
              expanded={!clickableMenuVisible}
            />
          </SliderMenu>
        </div>
        <div className="table-content__content">
          <div className="table-content__content__top">
            <div className="table-content__content--on-print">
              <SlideMenuContent
                handleSlider={handleSlider}
                fieldPageLink={data.fieldPageLink}
                expanded={!clickableMenuVisible}
              />
            </div>
            <div
              className="table-content__content_title"
              dangerouslySetInnerHTML={{ __html: data.fieldItemTitle }}
            />
            <div className="table-content__content_sub_title" />
          </div>
          <Glossary
            text={data.fieldBody}
            modifier="table-content__content-body"
          />
        </div>
      </div>
    </div>
  );
};

export { TableContent };
