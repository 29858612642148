import React, { useCallback } from 'react';
import { ModifierProps, useModifier, useTranslation } from '@icrc/react-ui';
import { MobileContainer } from '../mobile-container';
import { ResourceModel } from '../../models/resource';

import { Links } from '../../../links';
import { ImagePosition } from '../image-position';
import { FieldPageLink } from '../../models';

import './styles.scss';
import { useUrl } from '../../helper/url';

interface ResourceProps extends ModifierProps {
  data: ResourceModel;
}

const Resource: React.FC<ResourceProps> = ({ data, modifier }) => {
  const stylesCN = useModifier('resource', modifier);
  const { getUrl } = useUrl();
  const t = useTranslation();
  const getFieldPageLink = useCallback(
    (data: ResourceModel): FieldPageLink => {
      return {
        url: getUrl(data.fieldFile?.fieldMediaDocument?.uri),
        title: data.fieldCtaButton,
        options: {
          attributes: {
            target: '_blank',
          },
        },
      };
    },
    [getUrl]
  );

  return (
    <div className={stylesCN}>
      <ImagePosition
        modifier="left center no-mobile"
        img={getUrl(data.fieldGroupMedia?.fieldMediaImage?.uri)}
      >
        <MobileContainer>
          <div className="page-container columns resource__main_content">
            <div className="column col-12 resource__title_container">
              <span className="resource__resource_type">
                {data.fieldResourceType}
              </span>
              <br />
              <span className="resource__pre_title">
                {data.fieldItemPreTitle}
              </span>
              <br />
              <span className="resource__title">{data.fieldItemTitle}</span>
            </div>
            <div className="resource__container">
              <div
                className="resource__container_text"
                dangerouslySetInnerHTML={{ __html: data.fieldItemText }}
              />
              {data.fieldResourceFile.length !== 0 ? (
                <div className="resource__container_links">
                  <span className="links__text">
                    {t('resources.download-publication')}
                  </span>
                  <div className="links__link">
                    {data.fieldResourceFile.map((el: any, i: number) => {
                      return (
                        <Links key={el.id} fieldPageLink={getFieldPageLink(el)}>
                          {i < data.fieldResourceFile.length - 1 ? (
                            <span className="links__separator">|</span>
                          ) : (
                            ''
                          )}
                        </Links>
                      );
                    })}
                  </div>
                </div>
              ) : ''}
            </div>
          </div>
        </MobileContainer>
      </ImagePosition>
    </div>
  );
};

export { Resource };
