import React from 'react';
import { ModifierProps, useModifier } from '@icrc/react-ui';
import './styles.scss';

interface VideoProps extends ModifierProps {
  title: string;
  dataVideo: string;
}

const VideoContent: React.FC<VideoProps> = ({ modifier, dataVideo, title }) => {
  const stylesCN = useModifier('video-content', modifier);
  return (
    <div className={`${stylesCN}`}>
      <div className="video-content__aspect-ratio">
        <div dangerouslySetInnerHTML={{ __html: dataVideo }} />
      </div>
    </div>
  );
};

export { VideoContent };
