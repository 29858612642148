import React, { useCallback, useEffect, useState } from 'react';
import { Pagination, useTranslation } from '@icrc/react-ui';
import './styles.scss';
import { Highlight } from './highlight';

interface SearchResultsProps {
  results: any[];
  hasSearched: boolean;
  search: string;
}

const SearchResults: React.FC<SearchResultsProps> = ({
  results = [],
  hasSearched = false,
  search = '',
}) => {
  const t = useTranslation();
  const removeTags = useCallback((html: string) => {
    return html.replace(/<[^>]*>?/gm, '');
  }, []);

  const [currentPage, setCurrentPage] = useState(0);

  const itemsPerPage = 10;
  const offset = currentPage * itemsPerPage;
  const currentPageItems = results?.slice(offset, offset + itemsPerPage);

  const handleClickPage = (page: number) => setCurrentPage(page);

  useEffect(() => {
    setCurrentPage(0);
  }, [results]);

  return (
    <div className="search-results">
      {results.length !== 0 && (
        <span className="search-results__total">
          Showing <b>{results.length}</b> Results
        </span>
      )}
      {hasSearched ? (
        results.length ? (
          currentPageItems.map((result, index) => (
            <Highlight search={search}>
              <div key={index} className="search-results__items">
                <div className="search-results__items_title">
                  <a href={result.url}>{result.title}</a>
                </div>
                <div className="search-results__items_description">
                  {removeTags(result.description)}
                </div>
              </div>
            </Highlight>
          ))
        ) : (
          <div className="search-results__items search-results__items_title">
            {t('There is no result available for your search term.')}
          </div>
        )
      ) : null}

      {results.length !== 0 ? (
        <div className="search-results__pagination">
          <Pagination
            handleClickPage={handleClickPage}
            count={Math.ceil(results.length / 10)}
            currentPage={currentPage}
          />
        </div>
      ) : null}
    </div>
  );
};

export { SearchResults };
