import { useCallback } from 'react';
import { getQuestions } from '../../helper/filters';
import {
  PracticalQuestionsContext,
  update,
} from '../../../../../../pages/context';
import { useHistory } from 'react-router-dom';
import { useTranslationLanguage } from '@icrc/react-ui';
import { PracticalQuestion } from '../../../../models';
import { url } from '../../helper/url';

const usePracticalQuestions = () => {
  const [{ languageTag }] = useTranslationLanguage();
  const history = useHistory();
  const [
    practicalQuestions,
    dispatch,
  ] = PracticalQuestionsContext.useStore<PracticalQuestion>();

  const updateUrlQuery = useCallback(() => {
    const u = url.parse(window.location.toString());
    if (u.query !== practicalQuestions.filters) {
      const newQuery: any = {};
      Object.keys(practicalQuestions.filters)
        .filter(
          (key) =>
            practicalQuestions.filters[key] !== '0' &&
            practicalQuestions.filters[key] !== 0
        )
        .forEach(
          (key: string) => (newQuery[key] = practicalQuestions.filters[key])
        );
      u.query = newQuery;
      history.push(url.stringify(u));
    }
  }, [history, practicalQuestions.filters]);

  const applyFilters = useCallback(() => {
    getQuestions(languageTag, practicalQuestions.filters, 0).then(
      (response: any) => {
        dispatch(
          update({
            questions: response.questions,
            keyQuestions: response.keyQuestions,
            total: response.total,
            currentPage: 0,
          })
        );
        updateUrlQuery();
      }
    );
  }, [languageTag, practicalQuestions.filters, dispatch, updateUrlQuery]);

  const getPracticalQuestions = useCallback(
    (page: number, filters: any) => {
      getQuestions(languageTag, filters, page * 10).then((response: any) =>
        dispatch(
          update({
            questions: response.questions,
            keyQuestions: response.keyQuestions,
            total: response.total,
            currentPage: page,
            filters,
          })
        )
      );
    },
    [dispatch, languageTag]
  );

  return {
    applyFilters,
    practicalQuestions,
    dispatch,
    getPracticalQuestions,
  };
};

export { usePracticalQuestions };
