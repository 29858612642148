import React from 'react';
import { BlockGroupModel, GroupItemModel } from '../../models';
import { GroupItem } from '../group-item';
import { useUrl } from '../../helper/url';
import { ModifierProps, useModifier } from '@icrc/react-ui';

import './styles.scss';

interface StepsProps extends ModifierProps {
  data: BlockGroupModel;
}

const Steps: React.FC<StepsProps> = ({ data, modifier }) => {
  const { getUrl } = useUrl();
  const styleCN = useModifier('steps', modifier);

  return (
    <div className={styleCN}>
      <div className="columns">
        <div className="column col-12 col-sm-3 columns">
          <div className="steps__title">
            {data.fieldPreTitle && (
              <span
                className="steps__title--numeration"
                dangerouslySetInnerHTML={{ __html: data.fieldPreTitle }}
              />
            )}
            <span dangerouslySetInnerHTML={{ __html: data.fieldGroupTitle }} />
            {data.fieldGroupMedia?.fieldMediaImage?.uri && (
              <img
                src={getUrl(data.fieldGroupMedia?.fieldMediaImage?.uri)}
                alt="step"
              />
            )}
          </div>
        </div>
        <div className="column col-12 col-sm-9 columns">
          <div className="columns steps__content">
            {data.fieldGroupItem?.map(
              (item: GroupItemModel, indexCard: number) => (
                <div className="column col-12 col-sm-4" key={indexCard}>
                  <GroupItem item={item} />
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export { Steps };
