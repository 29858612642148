import React, { useEffect, useState } from 'react';
import { ComponentLoader } from '../../components/component-loader';
import { api } from '../../services';
import { useTranslationLanguage } from '@icrc/react-ui';
import { useHistory } from 'react-router-dom';
import { useFillMetadata } from './hooks/fill-metadata';

import './styles.scss';
import { imageModal } from '../../services/image';

function Main() {
  const [data, setData] = useState({});
  const [breadcrumb, setBreadcrumb] = useState([]);
  const [{ languageTag }] = useTranslationLanguage();

  const history = useHistory();
  const { injectMetadataInHead } = useFillMetadata();

  useEffect(() => {
    //TODO: hack until we have a well define routes
    const landingPageType = history.location.pathname
      .replace(/\/en/, '')
      .replace(/^\/$/, '');

    api.layout.getLandingPage(languageTag, landingPageType).then((resp) => {
      if (
        Array.isArray(resp.data) &&
        resp.data.length === 0 &&
        window.location.pathname !== '/404' &&
        window.location.pathname !== '/'
      ) {
        window.location.pathname = '/';
      }
      if (resp.data[0]) {
        setData(resp.data[0]);
        injectMetadataInHead(resp.data[0]);
        imageModal.atatchClickEvent();
      }
    });

    api.layout.getBreadcrumb(languageTag, landingPageType).then((resp) => {
      if (!resp.error) {
        setBreadcrumb(resp.meta.breadcrumb);
      }
    });
  }, [history, history.location.pathname, injectMetadataInHead, languageTag]);

  return (
    <div className="main">
      <ComponentLoader data={data} breadcrumb={breadcrumb} />
    </div>
  );
}

export { Main };
