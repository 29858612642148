import React, { forwardRef, ReactNode, Ref } from 'react';
import './styles.scss';
import { Modal, ModalRefProps, useModifier } from '@icrc/react-ui';

export interface SlideMenuRefProps extends ModalRefProps {}

export interface SliderMenuProps {
  handleSlider: (toClose: boolean) => () => void;
  isClickableBarVisible: boolean;
  children?: ReactNode;
}

export const SliderMenu = forwardRef<SlideMenuRefProps, SliderMenuProps>(
  (
    { handleSlider, isClickableBarVisible, children },
    ref: Ref<ModalRefProps>
  ) => {
    const stylesCN = useModifier('slide-menu', '', {
      'slide-menu--visible': isClickableBarVisible,
    });

    return (
      <div className={stylesCN}>
        <div className="slide-menu__main">
          <Modal
            ref={ref}
            modifier="left slide-left"
            onClose={handleSlider(true)}
          >
            <div className="slide-menu__container">{children}</div>
          </Modal>
        </div>
        <div
          className="slide-menu__clickable_bar"
          onClick={handleSlider(false)}
        />
      </div>
    );
  }
);
