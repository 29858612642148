import { Deserializer } from 'jsonapi-serializer';
import { customDeserializer } from '../../../deserializer';

export interface ErrorResponse {
  error?: boolean;
}

const deserializer = new Deserializer({
  keyForAttribute: 'camelCase',
  typeAsAttribute: true,
});

export async function parseResponse(
  response: Response
): Promise<ErrorResponse | any> {
  try {
    if (response.status < 200 || response.status > 299) {
      return Promise.resolve({ error: true });
    }
    if (response && response.headers && response.ok) {
      let json = { meta: null };
      return response
        .clone()
        .json()
        .then((apiJson) => {
          json = apiJson;
          if (apiJson.meta.hierarchy && apiJson.meta.hierarchy.length !== 0) {
            return Promise.resolve(customDeserializer(apiJson));
          }
          return deserializer.deserialize(apiJson);
        })
        .then((deserialized) => {
          return {
            data: deserialized,
            meta: json.meta,
          };
        })
        .catch(() => {
          //if deserialize doesn't work we try to return the json
          return response.json();
        })
        .catch((e) => {
          console.log('err', e);
        });
    }
  } catch (e) {
    /* ignore */
  }
  return Promise.resolve({ error: true });
}
