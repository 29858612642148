export type SriIconId =
  | 'facebook'
  | 'instagram'
  | 'linkedin'
  | 'rss'
  | 'share'
  | 'twitter'
  | 'youtube';

export type SriIconKey =
  | 'Facebook'
  | 'Instagram'
  | 'Linkedin'
  | 'Rss'
  | 'Share'
  | 'Twitter'
  | 'Youtube';

export enum SriIcon {
  Facebook = 'facebook',
  Instagram = 'instagram',
  Linkedin = 'linkedin',
  Rss = 'rss',
  Share = 'share',
  Twitter = 'twitter',
  Youtube = 'youtube',
}

export const SRI_ICON_CODEPOINTS: { [key in SriIcon]: string } = {
  [SriIcon.Facebook]: '61697',
  [SriIcon.Instagram]: '61698',
  [SriIcon.Linkedin]: '61699',
  [SriIcon.Rss]: '61700',
  [SriIcon.Share]: '61701',
  [SriIcon.Twitter]: '61702',
  [SriIcon.Youtube]: '61703',
};
