import React from 'react';
import './styles.scss';
import { Field, InputEvent, Icon, Icons } from '@icrc/react-ui';

interface PracticalQuestionsFilterItemProps {
  options: any[];
  handleSelectValue: (event: InputEvent) => void;
  label: string;
  name: string;
  value: any;
}

const PracticalQuestionsFilterItem: React.FC<PracticalQuestionsFilterItemProps> = ({
  options,
  handleSelectValue,
  label,
  name,
  value,
}) => {
  return (
    <div className="column col-12 col-md-2 practical-questions-filter-item">
      <Field
        type="select"
        name={name}
        value={value}
        label={label}
        options={options}
        onChange={handleSelectValue}
        elementWhenSelected={<Icon icon={Icons.Check} />}
      />
    </div>
  );
};

export { PracticalQuestionsFilterItem };
