import React, { createElement } from 'react';

interface IProps {
  children: any;
  search?: string;
}

export const Highlight = ({ children, search }: IProps) => {
  if (search === '' || !search) {
    return children;
  }

  const markSearch = (
    children?: any,
    HTMLtype?: any
  ): JSX.Element[] | JSX.Element | string => {
    const isString = typeof children === 'string';
    const isArray = Array.isArray(children as JSX.Element[]);
    const isElement = !isString && !isArray;

    if (!children) {
      return '';
    }

    if (isString) {
      if (!search || search === '') {
        return children;
      }

      const highlightKeyWord = (a: string, b: string[]) => {
        let highlightedWord = '';
        for (const c of b) {
          const regex = new RegExp(c, 'gi');
          if (
            a.toLowerCase().trim().includes(c.toLowerCase().trim()) &&
            c.toLowerCase().trim().length > 2
          ) {
            highlightedWord = a.replace(regex, (search) => {
              return `<strong>${search}</strong>`;
            });
          }
        }
        return { highlightedWord };
      };

      const sentenceText = (children: string, match: string) => {
        const sentence = children.split(/[,.\s]/);
        const inputSentence = match.split(/[,.\s]/) as any;

        return sentence
          .map((word: string) => {
            const { highlightedWord } = highlightKeyWord(word, inputSentence);
            return highlightedWord && match.length > 2 ? highlightedWord : word;
          })
          .join(' ');
      };

      const markedElementString = sentenceText(children, search);

      return createElement(HTMLtype || 'span', {
        dangerouslySetInnerHTML: { __html: markedElementString },
      });
    }

    if (isArray) {
      return children.map((child: JSX.Element) => markSearch(child));
    }

    if (isElement) {
      const { children: childChildren, ...props } = children.props;
      const newChildren = markSearch(childChildren);
      return createElement(HTMLtype || children?.type, {
        ...props,
        children: newChildren,
      });
    }

    return children;
  };

  return <div>{markSearch(children)}</div>;
};
