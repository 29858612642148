import { SocialSharing, useTranslation } from '@icrc/react-ui';
import React, { useEffect, useState } from 'react';
import { api } from '../../../../services';
import { imageModal } from '../../../../services/image';
import { IconSRI, SriIcon } from '../../../icon-sri';
import Calendar from '../../../icon-sri/assets/Calendar.svg';
import Location from '../../../icon-sri/assets/Location.svg'
import { dateAndTimeFormatter } from '../events-list/helper/dateFormater';
import './styles.scss';

const EventDetails: React.FC = () => {
  const [eventDetails, setEventDetails] = useState([]);
  const id = window.location.pathname;
  const t = useTranslation();

  useEffect(() => {
    api.eventsList.getEventsList().then((resp) => {
      if (resp?.data[0]) {
        setEventDetails(resp?.data);
        imageModal.atatchClickEvent();
      }
    });
  }, []);

  const singleEvent: any = eventDetails.find(
    (event: any) => event.parentId === id
  );
  
  return (
    <div className="page-container">
      <div className="event-details">
        <div className="event-details__title">
          {singleEvent?.fieldEventName}
        </div>
        <div className="event-details__venue-info">
          <div className="event-details__date">
            <img src={Calendar} alt="calendar" className="date__icon"/>
            <div className="date__container">
              <div className="date__title">
                {t('event-details.date-time')}
              </div>
              <div className="date__date-time">
                {t('event-details.date-from')} {dateAndTimeFormatter(singleEvent?.fieldDateTime?.value)}
                <br />
                {t('event-details.date-to')} {dateAndTimeFormatter(singleEvent?.fieldDateTime?.endValue)}
              </div>
            </div>
          </div>
          <div className="event-details__location">
            <img src={Location} alt="" className="location__icon"/>
            <div className="location__container">
              <div className="location__title">
                {t('event-details.location')}
              </div>
              <div className="location__address">
                {singleEvent?.fieldVenueAddress}
              </div>
            </div>
          </div>
        </div>
        <div
          className="event-details__text"
          dangerouslySetInnerHTML={{
            __html: singleEvent?.fieldEventDescription.value,
          }}
        ></div>
        <div className="social-media-sharing">
          <span className="social-media-sharing__text">{t('social-media.share-on')}</span>
          <SocialSharing link={window.location.href} type="twitter">
            <IconSRI icon={SriIcon.Twitter} />
          </SocialSharing>
          <SocialSharing link={window.location.href} type="facebook">
            <IconSRI icon={SriIcon.Facebook} />
          </SocialSharing>
          <SocialSharing link={window.location.href} type="linkedin">
            <IconSRI icon={SriIcon.Linkedin} />
          </SocialSharing>
        </div>
      </div>
    </div>
  );
};


export { EventDetails };
