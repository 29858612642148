import { RestApi } from '../../../components';

export const createEventsApi = ({ GET }: RestApi) => {
  const getEventsList = (): Promise<any> => {
    return GET(`/jsonapi/views/events/all_events`);
  };

  const getMoreEvents = (pageNum:number): Promise<any> => {
    return GET(`/jsonapi/views/events/all_events?page=${pageNum}`);
  };

  return {
    getEventsList,
    getMoreEvents,
  };
};
