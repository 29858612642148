import { RestApi } from '../../../components';
import { JsonApiParsedResponse } from '../models/jsonApiParsed.response';

export const createLayoutApi = ({ GET }: RestApi) => {
  function getMainHeader(language: string): Promise<any> {
    return GET(`/${language}/jsonapi/menu_items/main`);
  }

  function getBrandingHeader(
    block_type: string,
    language: string
  ): Promise<any> {
    return GET(
      `/jsonapi/block_content/${block_type}?include=field_site_logo&fields[file--file]=uri&language_content_entity=${language}`
    );
  }

  function getBreadcrumb(language: string, page: string): Promise<any> {
    return GET(
      `/${language}/jsonapi/breadcrumb?filter[field_path][value]=${page}`
    );
  }

  const getLandingPage = (
    langCode: string,
    page = ''
  ): Promise<JsonApiParsedResponse> => {
    return GET(
      `/${langCode}/jsonapi/node/landing_page?filter[field_path][value]=${page}&include=field_group,field_group.field_group_item,field_group.field_background_image.field_media_image,field_group.field_group_media.field_media_image,field_group.field_resource_file.field_file.field_media_document,field_group.field_reference_list&fields[node--practical_questions]=title`
    );
  };

  function getFooter(language: string): Promise<any> {
    return GET(
      `/${language}/jsonapi/block_content/footer?include=field_social_networks`
    );
  }

  const search = (language: string, search: string): Promise<any> => {
    return GET(`/${language}/jsonapi/index/sri_search?include=field_group&\
filter[type-filter][condition][path]=type&\
filter[type-filter][condition][operator]=IN&\
filter[type-filter][condition][value][]=text&\
filter[type-filter][condition][value][]=table_of_contents&\
filter[fulltext]=${search}&\
fields[node--landing_page]=title,field_path,path,field_group&\
fields[paragraph--text]=field_body&\
fields[paragraph--block_group]&\
fields[paragraph--hero]&\
page[limit]=${search}`);
  };

  return {
    getMainHeader,
    getBrandingHeader,
    getBreadcrumb,
    getLandingPage,
    getFooter,
    search,
  };
};
