import React, { useEffect, useState } from 'react';
import {
  Button,
  Icon,
  Icons,
  noop,
  SocialSharing,
  useTranslation,
} from '@icrc/react-ui';
import { extractContentSlider } from '../../helpers/extract-content-slider';
import { SlideMenu } from '../../models/slide-menu';
import { IconSRI, SriIcon } from '../../../../../icon-sri';
import { FieldPageLink } from '../../../../models';
import { Print } from '../../../../../print';

import './styles.scss';

interface SlideMenuContentProps {
  handleSlider: (toClose: boolean) => () => void;
  fieldPageLink?: FieldPageLink;
  expanded?: boolean;
}

const SlideMenuContent: React.FC<SlideMenuContentProps> = ({
  handleSlider,
  fieldPageLink,
  expanded,
}) => {
  const t = useTranslation();
  const [contentSlideMenu, setContentSlideMenu] = useState<SlideMenu[]>([]);

  useEffect(() => {
    setTimeout(() => setContentSlideMenu(extractContentSlider()), 1);
  }, []);

  return (
    <div className={`slide-menu-content`}>
      <div className="slide-menu-content__title">
        {expanded ? t('Content') : t('Content').charAt(0)}
      </div>

      <div className="slide-menu-content__items">
        {contentSlideMenu.map((item, index) => (
          <div className="slide-menu-content__item_container" key={index}>
            <div className="slide-menu-content__item slide-menu-content__item--primary">
              <a href={` #${item.anchor}`} onClick={handleSlider(true)}>
                {expanded ? item.title : item.title?.split(' ')[0]}
              </a>
            </div>
            {item.subItem.map((subItem, subItemIndex) => (
              <div
                className="slide-menu-content__item slide-menu-content__item--secondary"
                key={subItemIndex}
              >
                <a href={`#${subItem.anchor}`} onClick={handleSlider(true)}>
                  {expanded ? subItem.title : subItem.title?.split(' ')[0]}
                </a>
              </div>
            ))}
          </div>
        ))}
      </div>
      {fieldPageLink?.title ? (
        <div className="slide-menu-content__action">
          <Button
            modifier="practical-questions arrow"
            onClick={noop}
            label={<a href={fieldPageLink?.url}>{fieldPageLink?.title}</a>}
          />
        </div>
      ) : null}

      <div className="slide-menu-content__action slide-menu-content__action--share ">
        <IconSRI icon={SriIcon.Share} />
        <div className="slide-menu-content__action__content">
          <span className="social-media-sharing__text">
            {t('social-media.share-on')}
          </span>
          <SocialSharing link={window.location.href} type="twitter">
            <IconSRI icon={SriIcon.Twitter} />
          </SocialSharing>
          <SocialSharing link={window.location.href} type="facebook">
            <IconSRI icon={SriIcon.Facebook} />
          </SocialSharing>

          <SocialSharing link={window.location.href} type="linkedin">
            <IconSRI icon={SriIcon.Linkedin} />
          </SocialSharing>

          <SocialSharing link={window.location.href} type="mail">
            <Icon icon={Icons.Email} />
          </SocialSharing>
          <Print />
        </div>
      </div>
    </div>
  );
};

export { SlideMenuContent };
