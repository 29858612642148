import React, { useCallback, useEffect, useState } from 'react';
import {
  Expandable,
  Flex,
  Icon,
  Icons,
  SocialSharing,
  useTranslationLanguage,
} from '@icrc/react-ui';
import { api } from '../../../../services';
import { JsonApiTree } from '../../../deserializer/models/json-api-tree';
import { Search } from '../search';
import { useHistory } from 'react-router-dom';

import './styles.scss';
import { normalizeLink } from '../../../helper';

const MainMenu: React.FC<any> = () => {
  const [menu, setMenu] = useState<JsonApiTree[]>([]);
  const [showMenu, setShowMenu] = useState(false);
  const [{ languageTag }] = useTranslationLanguage();

  const history = useHistory();

  const parseItem = useCallback(
    (data: JsonApiTree[]): JsonApiTree[] => {
      const inject = (level: JsonApiTree, level1: JsonApiTree): boolean => {
        const url = normalizeLink(level.url);
        if (
          (url !== '' && history.location.pathname.includes(url)) ||
          (normalizeLink(history.location.pathname) === '' && url === '')
        ) {
          level1.classes = 'main-menu__item--active';
          return true;
        }
        level1.classes = '';
        if (Array.isArray(level.subItems) && level.subItems.length > 0) {
          return level.subItems.some((l) => inject(l, level1));
        }
        return false;
      };
      data.some((l1) => inject(l1, l1));
      return data;
    },
    [history.location.pathname]
  );

  useEffect(() => {
    api.layout.getMainHeader(languageTag).then((mainMenu) => {
      setMenu(parseItem(mainMenu.data));
    });
  }, [languageTag, parseItem]);

  const handleOpenMenu = useCallback(() => {
    setShowMenu(!showMenu);
  }, [showMenu]);

  return (
    <div className="main-menu">
      <div className="page-container">
        <div className="main-menu__mobile">
          <div className="main-menu__actions">
            <Flex modifier="end">
              <div className="social-sharing">
                <SocialSharing type="mail" link={window.location.href}>
                  <Icon modifier={'white'} icon={Icons.Email} />
                </SocialSharing>
              </div>
              <div
                className={`action ${showMenu ? 'active' : ''}`}
                onClick={handleOpenMenu}
              >
                <Icon icon={Icons.Menu} />
              </div>
              <div className="action">
                <Search
                  setShowMenu={setShowMenu}
                />
              </div>
            </Flex>
          </div>

          <div className="main-menu__item">
            {showMenu &&
              menu.map((item: JsonApiTree, index: number) =>
                item.subItems &&
                item.subItems.length > 0 &&
                !item.subItems
                  .map((item: any) => item.subItems.length)
                  .includes(0) ? (
                  <div className={`${item.classes}`} key={index}>
                    <Expandable
                      showBtnExpand={false}
                      header={
                        <div className="main-menu__header">{item.title}</div>
                      }
                    >
                      <div className="sub-menu">
                        {item.subItems.map(
                          (subItem: JsonApiTree, iSubItem: number) => (
                            <div key={iSubItem}>
                              <div className="sub-menu__sub-item-divider">
                                <span>{subItem.title}</span>
                              </div>

                              {subItem.subItems &&
                                subItem.subItems.map(
                                  (
                                    subSubItem: JsonApiTree,
                                    isubSubItem: number
                                  ) => (
                                    <div
                                      key={isubSubItem}
                                      className="sub-menu__sub-item"
                                    >
                                      <a href={normalizeLink(subSubItem.url)}>
                                        {subSubItem.title}
                                      </a>
                                    </div>
                                  )
                                )}
                            </div>
                          )
                        )}
                      </div>
                    </Expandable>
                  </div>
                ) : (
                  <div className={`${item.classes}`} key={index}>
                    <a className="main-menu__header" href={item.url}>
                      {item.title}
                    </a>
                  </div>
                )
              )}
          </div>
        </div>
        <div className="main-menu__desktop">
          <div>
            {menu.map((item: JsonApiTree, index: number) => (
              <div className={`main-menu__item ${item.classes}`} key={index}>
                <a href={item.url}>
                  <div className="main-menu__header">
                    <span>{item.title}</span>
                  </div>
                </a>

                {item.subItems &&
                  item.subItems.length > 0 &&
                  !item.subItems
                    .map((item: any) => item.subItems.length)
                    .includes(0) && (
                    <div className="sub-menu">
                      {item.subItems &&
                        item.subItems.map(
                          (subItem: JsonApiTree, iSubItem: number) => (
                            <div key={iSubItem}>
                              <div className="sub-menu__sub-item-divider">
                                <span>{subItem.title}</span>
                              </div>

                              {subItem.subItems &&
                                subItem.subItems.map(
                                  (
                                    subSubItem: JsonApiTree,
                                    iSubSubItem: number
                                  ) => (
                                    <div
                                      key={iSubSubItem}
                                      className="sub-menu__sub-item"
                                    >
                                      <a href={normalizeLink(subSubItem.url)}>
                                        {subSubItem.title}
                                      </a>
                                    </div>
                                  )
                                )}
                            </div>
                          )
                        )}
                    </div>
                  )}
              </div>
            ))}
          </div>
          <div className="social-sharing">
            <SocialSharing type="mail" link={window.location.href}>
              <Icon modifier={'white'} icon={Icons.Email} />
            </SocialSharing>
          </div>
          <Search
            setShowMenu={setShowMenu}
          />
        </div>
      </div>
    </div>
  );
};

export { MainMenu };
