import { useTranslation } from '@icrc/react-ui';
import React, { useEffect, useState } from 'react';

import './styles.scss';

import ReactGA from 'react-ga';
import { store } from '../../../core/store';

const GA_ID = 'UA-40697345-5';

interface Window {
  _paq?: any[];
  location: any;
}

interface ITrackerProps {
  window: Window;
}

const GoogleAnalyticsTracker: React.FC<ITrackerProps> = ({ window }) => {
  const [showDisclaimer, setShowDisclaimer] = useState<any>(null);
  const t = useTranslation();
  const tracking = localStorage.getItem('initialized_tracking');

  useEffect(() => {
    if (!localStorage.getItem('initialized_tracking')) {
      setShowDisclaimer(true);
    }
  }, []);

  useEffect(() => {
    if (tracking && JSON.parse(tracking) === true) {
      matomoTracking();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tracking]);

  const initTracker = () => {
    ReactGA.initialize(GA_ID);
    ReactGA.pageview(window?.location.pathname + window?.location.search);
    setShowDisclaimer(false);
    localStorage.setItem('initialized_tracking', JSON.stringify(true));
  };

  const handleReject = () => {
    store.set('rejected', true);
    setShowDisclaimer(false);
    localStorage.setItem('initialized_tracking', JSON.stringify(false));
  };

  const matomoTracking = () => {
    var _paq = window?._paq || [];
    _paq.push(['rememberConsentGiven']);
    _paq.push(['trackPageView']);
    _paq.push(['enableLinkTracking']);
  };

  return (
    <>
      {showDisclaimer ? (
        <div className="google-analytics-tracker">
          <div className="columns page-container">
            <div className="column col-md-9 google-analytics__disclaimer">
              {t('google-analytics.disclaimer')}{' '}
              <a href="/privacy-policy">{t('google-analytics.learn-more')}</a>
            </div>
            <div className="column col-md-3 col-12 google-analytics__buttons">
              <div
                className="column col-xl-5 google-analytics__button"
                onClick={initTracker}
              >
                {t('google-analytics.accept')}
              </div>
              <div
                className="column col-xl-6 google-analytics__button secondary"
                onClick={handleReject}
              >
                {t('google-analytics.reject')}
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export { GoogleAnalyticsTracker };
