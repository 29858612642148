import { DEFAULT_FILTER_OPTION } from '../components/practical-questions-list/helper/filters';

export interface PracticalQuestion {
  questions: [];
  keyQuestions: [];
  filters: any;
  total: number;
  currentPage: number;
}

export const DEFAULT_PRACTICAL_QUESTIONS: PracticalQuestion = {
  filters: {
    field_conflict_related_activity: DEFAULT_FILTER_OPTION.value,
    field_type_of_actor: DEFAULT_FILTER_OPTION.value,
    field_area_of_practical_measure: DEFAULT_FILTER_OPTION.value,
    field_protection_concern: DEFAULT_FILTER_OPTION.value,
    field_side_of_support_relationsh: DEFAULT_FILTER_OPTION.value,
    field_category_of_support: DEFAULT_FILTER_OPTION.value,
  },
  questions: [],
  keyQuestions: [],
  total: 0,
  currentPage: 0,
};
