import React, { useCallback, useEffect, useState } from 'react';
import { BlockGroupModel } from '../../models';
import { ModifierProps } from '@icrc/react-ui';
import './styles.scss';
import { GroupItem } from '../group-item';

interface GridProps extends ModifierProps {
  block: BlockGroupModel;
}

const Grid: React.FC<GridProps> = ({ block }) => {
  const [groupItemGrid, setGroupItemGrid] = useState<any[]>([]);

  useEffect(() => {
    const result: any[] = [];
    let aux: any[] = [];

    block.fieldGroupItem?.forEach((value, index) => {
      if (index % 3 === 0) {
        aux = [];
        result.push(aux);
      }
      aux.push(value);
    });
    setGroupItemGrid(result);
  }, [block.fieldGroupItem]);

  const calculateColSm = useCallback((totalInRow: number) => {
    return 12 / totalInRow;
  }, []);

  return (
    <div className="grid">
      <div className="columns">
        {groupItemGrid.map((childItem: [], indexGroupItemGrid: number) =>
          childItem.map((item: any, indexItem: number) => (
            <div
              className={`column col-12 col-sm-${calculateColSm(
                childItem.length
              )}`}
              key={`${indexItem}-${indexGroupItemGrid}`}
            >
              <div
                className={`grid__item ${
                  indexItem === childItem.length - 1 ? 'grid__item--last' : ''
                }`}
                key={`${indexItem}-${indexGroupItemGrid}`}
              >
                <GroupItem item={item} modifier="white" />
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export { Grid };
