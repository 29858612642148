import React from 'react';
import { Links } from '../../../links';
import { ModifierProps, useModifier } from '@icrc/react-ui';
import { GroupItemModel } from '../../models';
import './styles.scss';

interface GroupItemProps extends ModifierProps {
  item: GroupItemModel;
}

const GroupItem: React.FC<GroupItemProps> = ({ item, modifier }) => {
  const stylesCN = useModifier('group-item', modifier);

  return (
    <div className={stylesCN}>
      {item.fieldItemPreTitle && (
        <div
          className="group-item__pre_title"
          dangerouslySetInnerHTML={{ __html: item.fieldItemPreTitle }}
        />
      )}

      <div
        className="group-item__title"
        dangerouslySetInnerHTML={{ __html: item.fieldItemTitle }}
      />
      <br />
      <div
        className="group-item__text"
        dangerouslySetInnerHTML={{ __html: item.fieldItemText }}
      />
      <Links fieldPageLink={item.fieldPageLink} modifier="arrow" />
    </div>
  );
};

export { GroupItem };
