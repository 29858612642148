import { CancelablePromise } from './extensions';

function cancelableFetch(
  input: RequestInfo,
  init?: RequestInit
): CancelablePromise<Response> {
  const controller = new AbortController();
  const { signal } = controller;
  const request = { ...init, signal };
  const f = fetch(input, request)
    .then((response) => {
      return response;
    })
    .catch((e) => {
      if (e && e.name === 'AbortError') {
        return Promise.reject('Canceled');
      }
      return Promise.reject((e && e.name) || 'Unknown');
    }) as CancelablePromise<Response>;
  /**/
  f.cancel = () => controller.abort();
  return f;
}

export default cancelableFetch;
