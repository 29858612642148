import React, { useEffect, useState } from 'react';
import './styles.scss';
import { Button, useTranslationLanguage, noop } from '@icrc/react-ui';
import { api } from '../../../../services';
import Logo from '../../../../assets/images/icrc.svg';
import { FieldPageLink } from '../../../component-loader/models';

const TopMenu: React.FC = () => {
  const [header, setHeader] = useState<any>({});
  const [{ languageTag }] = useTranslationLanguage();

  useEffect(() => {
    api.layout.getBrandingHeader('branding_header', languageTag).then((h) => {
      setHeader(h.data[0]);
    });
  }, [languageTag]);

  if (header) {
    return (
      <div className="page-container">
        <div className="top-menu">
          <div className="columns">
            <div className="column col-2 col-sm-1 top-menu__logo">
              <a href="/">
                <img src={Logo} className="no-popup" alt="logo" />
              </a>
            </div>
            <div className="column col-8 col-sm-9 top-menu__title">
              <div className="top-menu__title--secondary">
                <span>{header.fieldSiteSlogan}</span>
              </div>
              <div className="top-menu__title--primary">
                {header.fieldSiteName}
              </div>
            </div>
            <div className="column col-2 col-sm-2">
              <div className="top-menu__items top-menu__items--right-alignment">
                <div className="top-menu__language"></div>
                <div className="top-menu__extra-links">
                  {header.fieldSiteLinks &&
                    header.fieldSiteLinks.map(
                      (item: FieldPageLink, index: number) => (
                        <a
                          key={index}
                          href={item.url}
                          rel="noopener noreferrer"
                          target={item.options?.attributes?.target}
                        >
                          <Button onClick={noop} modifier="secondary">
                            {item.title}
                          </Button>
                        </a>
                      )
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export { TopMenu };
