import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
} from 'react-router-dom';
import * as Page from './pages';
import { Footer, Header, MainMenu } from './components';
import './app.scss';
import { TranslationProvider } from '@icrc/react-ui';
import en from './translations/en.json';
import { GoogleAnalyticsTracker } from './components/component-loader/components/google-analytics-tracker';
import { ImageModalContainer } from './services/image';
import { tagManagerArgs } from './components/helper';
import TagManager from 'react-gtm-module';
import { useMatomo } from 'react-matomo';

interface Window {
  _paq?: any;
  location: any;
}

interface ITrackerProps {
  window: Window;
}

const App: React.FC<ITrackerProps> = ({ window }) => {
  const { trackPageView } = useMatomo();
  const history = useHistory();
  const tracking = localStorage.getItem('initialized_tracking');

  useEffect(() => {
    if (tracking && JSON.parse(tracking) === true) {
      TagManager.initialize(tagManagerArgs);
      trackPageView();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tracking]);

  useEffect(() => {
    return history?.listen((location: any) => {
      // registerPageLoad();
      var _paq = window?._paq || [];
      _paq.push(['trackPageView']);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const registerPageLoad = () => {
  //   var _paq = window?._paq || [];
  //   _paq.push(['setCustomUrl', history.location.pathname]);
  //   _paq.push(['setDocumentTitle', history.location.pathname]);
  //   _paq.push(['trackPageView']);
  // };

  return (
    <TranslationProvider translations={{ en }}>
      <div>
        <Router>
          <GoogleAnalyticsTracker window={window} />
          <Header>
            <MainMenu />
          </Header>
          <Switch>
            <Route path="/">
              <Page.Main />
            </Route>
          </Switch>
        </Router>
        <Footer />
        <ImageModalContainer />
      </div>
    </TranslationProvider>
  );
};

export default App;
