import React, { useCallback, useEffect, useState } from 'react';
import { api } from '../../services';
import { useTranslationLanguage } from '@icrc/react-ui';
import { normalizeLink } from '../helper';

import './styles.scss';
import { SriIcon, IconSRI } from '../icon-sri';
import { FieldPageLink } from '../component-loader/models';

const iconMapping = {
  twitter: SriIcon.Twitter,
  facebook: SriIcon.Facebook,
  instagram: SriIcon.Instagram,
  linkedin: SriIcon.Linkedin,
  youtube: SriIcon.Youtube,
  rss: SriIcon.Rss,
};
type iconMappingType = keyof typeof iconMapping;

const Footer: React.FC = () => {
  const [data, setData] = useState<any>({});
  const [{ languageTag }] = useTranslationLanguage();

  useEffect(() => {
    api.layout.getFooter(languageTag).then((response) => {
      setData(response.data[0]);
    });
  }, [languageTag]);

  const getIcon = useCallback((iconType: iconMappingType) => {
    return iconMapping[iconType];
  }, []);

  return (
    <div className="footer">
      <div className="footer__container">
        <div className="page-container">
          {data.fieldSocialNetworks &&
            data.fieldSocialNetworks.map((item: any, index: number) => (
              <div key={index} className="footer__icons">
                <a
                  target={item.fieldPageLink?.options?.attributes?.target}
                  rel="noopener noreferrer"
                  href={item.fieldPageLink?.url}
                >
                  <IconSRI
                    modifier="grey round"
                    icon={getIcon(item.fieldSocialNetwork)}
                  />
                </a>
              </div>
            ))}
        </div>

        <div className="footer__separator" />

        <div className="page-container">
          <div className="footer__text">
            {data.fieldSiteLinks &&
              data.fieldSiteLinks.map((item: FieldPageLink, index: number) => (
                <div
                  key={index}
                  className={
                    index === 0 ? 'footer__text' : 'footer__text-bullet'
                  }
                >
                  <a
                    target={item.options?.attributes?.target}
                    rel="noopener noreferrer"
                    href={
                      item.url === 'route:<nolink>'
                        ? undefined
                        : normalizeLink(item.url)
                    }
                  >
                    {item.title}
                  </a>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export { Footer };
