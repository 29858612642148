import React, { useCallback } from 'react';
import { Links } from '../../../links';
import { ModifierProps, useModifier } from '@icrc/react-ui';
import { HeroModel } from '../../models';
import { useUrl } from '../../helper/url';

import './styles.scss';

interface HeroProps extends ModifierProps {
  hero: HeroModel;
}

const Hero: React.FC<HeroProps> = ({ hero, modifier }) => {
  const { getUrl } = useUrl();
  const stylesCN = useModifier('hero', modifier, {
    visible: hero,
  });

  const injectHtml = useCallback((html: string) => {
    return {
      __html: html,
    };
  }, []);

  return (
    <div
      className={stylesCN}
      style={{
        backgroundImage: `url(${getUrl(
          hero.fieldBackgroundImage?.fieldMediaImage?.uri
        )})`,
      }}
    >
      <div className="page-container">
        <div
          className="hero__title"
          dangerouslySetInnerHTML={injectHtml(hero?.fieldTitleLong || '')}
        />
        <div
          className="hero__description"
          dangerouslySetInnerHTML={injectHtml(hero?.fieldBody || '')}
        />
        <Links fieldPageLink={hero?.fieldPageLink} modifier="arrow" />
      </div>
    </div>
  );
};

export { Hero };
