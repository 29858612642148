import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import './index.scss';
import { MatomoProvider, createInstance } from 'react-matomo';
import { SRI_ICRC_ORG } from './config-envs';

const checker = window?.location.href.includes(SRI_ICRC_ORG);
let u;
if (checker) {
  u = '//matomo.ext.icrc.org/';
} else {
  u = '//matomo-ext.test.icrc.org/';
}

ReactDOM.render(
  <React.StrictMode>
    <MatomoProvider
      value={createInstance({
        url: u,
        siteId: 6,
      })}
    >
      <App window={window} />
    </MatomoProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
