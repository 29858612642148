export interface Querystring {
  [key: string]: any;
}

function stringifyQuery(query?: any) {
  if (query) {
    return `?${Object.keys(query)
      .map((key) => key + '=' + String(query[key]))
      .join('&')}`;
  }
  return '';
}

export const query = {
  stringify: stringifyQuery,
};
