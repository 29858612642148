import { useCallback, useEffect } from 'react';

const useOutsideClick = (ref: any, callback: () => void) => {
  const handleClick = useCallback(
    (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    },
    [callback, ref]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [handleClick]);
};

export { useOutsideClick };
