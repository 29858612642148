export const update = (value: any) => ({
  type: 'update',
  ...value,
});

export const reset = (resetValue?: any) => ({
  type: 'reset',
  resetValue,
});

export const loading = (loading: boolean) => ({
  type: 'loading',
  loading,
});
