import React from 'react';
import { Carousel, ModifierProps } from '@icrc/react-ui';
import { BlockGroupModel } from '../../models';
import { GroupItem } from '../group-item';

import './styles.scss';

interface CarouselContainerProps extends ModifierProps {
  block: BlockGroupModel;
}

const CarouselContainer: React.FC<CarouselContainerProps> = ({ block }) => {
  return (
    <div className="carousel-container">
      <Carousel numberOfItems={block?.fieldGroupItem.length || 0}>
        {block.fieldGroupItem.map((item, index) => (
          <div
            className="column col-12 col-sm-4 carousel-container__item"
            key={index}
          >
            <GroupItem item={item} />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export { CarouselContainer };
