import { useCallback } from 'react';

const useFillMetadata = () => {
  const injectMetadata = useCallback((metatag: any) => {
    const meta: any = document.createElement(`${metatag.tag}`);
    Object.keys(metatag.attributes).forEach((key: string) => {
      meta[key] = metatag.attributes[key];
    });
  }, []);

  const injectTitle = useCallback((metatag: []) => {
    metatag.map((meta: any) => {
      switch (meta.tag) {
        case 'link':
          const linkTag: any = document.createElement('link');
          linkTag.href = meta?.attributes?.href || '';
          linkTag.rel = meta?.attributes?.rel || '';
          return document.getElementsByTagName('head')[0].appendChild(linkTag);

        case 'meta':
          const metaTag: any = document.createElement('meta');
          metaTag.name = meta?.attributes?.name || '';
          metaTag.setAttribute('property', meta?.attributes?.property || '');
          metaTag.content = meta?.attributes?.content || '';
          return document.getElementsByTagName('head')[0].appendChild(metaTag);

        default:
          return null;
      }
    });

    const title: any = metatag.find(
      (meta: any) => meta.attributes?.name === 'title'
    );
    const meta: any = document.createElement('title');
    meta.text = title?.attributes?.content || '';
    document.getElementsByTagName('head')[0].appendChild(meta);
  }, []);

  const injectMetadataInHead = useCallback(
    (data?: any) => {
      if (
        data &&
        data.metatagNormalized &&
        Array.isArray(data.metatagNormalized)
      ) {
        data.metatagNormalized.forEach(injectMetadata);
        injectTitle(data.metatagNormalized);
      }
    },
    [injectMetadata, injectTitle]
  );

  return {
    injectMetadataInHead,
  };
};

export { useFillMetadata };
