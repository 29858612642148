import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { api } from '../../../../services';
import './styles.scss';

interface GlossaryProps {
  text: any;
  modifier: any;
}

const Glossary: React.FC<GlossaryProps> = ({ text, modifier }) => {
  const [glossary, setGlossary]: any = useState(
    JSON.parse(localStorage.getItem('glossary') || '{}')
  );

  const getGlossary = () => {
    const glossary = JSON.parse(localStorage.getItem('glossary') || '{}');
    const glossaryTimestamp = glossary?.timestamp
      ? new Date(glossary?.timestamp)
      : undefined;
    if (
      !glossary?.data ||
      (Array.isArray(glossary?.data) && glossary?.data.length === 0) ||
      !glossaryTimestamp ||
      glossaryTimestamp < new Date()
    ) {
      api.glossary
        .getGlossary()
        .then((resp) => {
          if (resp?.data[0]) {
            const glossaryTimestamp = new Date();
            glossaryTimestamp.setDate(glossaryTimestamp.getDate() + 1);
            const glossary = { data: resp?.data, timestamp: glossaryTimestamp };
            setGlossary(glossary);
            localStorage.setItem('glossary', JSON.stringify(glossary));
          }
        })
        .catch((err) => {
          console.warn(err);
        });
    }
  };

  useEffect(getGlossary, []);

  const terms = useMemo(() => glossary?.data?.map((el: any) => el.name), [
    glossary,
  ]);

  const re = useMemo(() => new RegExp(terms?.join('|'), 'gi'), [terms]);

  const findSingleDefinition = useCallback(
    (matched: any) => {
      let definition = glossary.data.find(
        (el: any) => matched.toLowerCase() === el.name.toLowerCase()
      );
      let cleanDescription = definition?.description?.value.replace(
        /<\/?[^>]+(>|$)/g,
        ''
      );
      return cleanDescription;
    },
    [glossary.data]
  );

  const modifiedText = useMemo(
    () =>
      glossary?.data
        ? text?.replace(
            re,
            (matched: any) =>
              `<span class="glossary-tooltip" data-tip="${findSingleDefinition(
                matched
              ).replace('"', '')}"><u>${matched}</u></span>`
          )
        : text,
    [glossary?.data, text, re, findSingleDefinition]
  );

  return (
    <div
      className={modifier}
      dangerouslySetInnerHTML={{ __html: modifiedText }}
    />
  );
};

export { Glossary };
