import React from 'react';
import './styles.scss';
import { ModifierProps, useModifier } from '@icrc/react-ui';
import { FieldPageLink } from '../component-loader/models';
import { normalizeLink } from '../helper';

interface LinksProps extends ModifierProps {
  fieldPageLink?: FieldPageLink;
}

const Links: React.FC<LinksProps> = ({ fieldPageLink, modifier, children }) => {
  const stylesCN = useModifier('links', modifier, {
    'links--visible': fieldPageLink?.url,
  });

  return (
    <a
      className={stylesCN}
      href={normalizeLink(fieldPageLink?.url)}
      target={fieldPageLink?.options?.attributes?.target}
      rel="noopener noreferrer"
    >
      {fieldPageLink?.title || 'Read More'}
      {children}
    </a>
  );
};

export { Links };
