const SESSION = {
  session: {},
};

async function getSession(): Promise<any> {
  return Promise.reject(SESSION.session);
}

async function setSession(session: {}) {
  SESSION.session = { ...SESSION.session, ...session };
}

export { setSession, getSession };
