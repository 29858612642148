import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Field,
  Icon,
  Icons,
  useModifier,
  useTranslation,
} from '@icrc/react-ui';
import { PracticalQuestionsFilterItem } from '../practical-questions-filter-item';
import {
  AllFilters,
  DEFAULT_FILTER_OPTION,
  getAllFilters,
} from '../../helper/filters';
import { update } from '../../../../../../pages/context';
import './styles.scss';
import { usePracticalQuestions } from '../../hooks/practical-questions';

const PracticalQuestionsFilter: React.FC = () => {
  const [filtersAvailable, setFiltersAvailable] = useState<AllFilters>({
    activities: [DEFAULT_FILTER_OPTION],
    actor: [DEFAULT_FILTER_OPTION],
    areaOfPracticalMeasures: [DEFAULT_FILTER_OPTION],
    protectionConcern: [DEFAULT_FILTER_OPTION],
    sideOfRelationship: [DEFAULT_FILTER_OPTION],
    support: [DEFAULT_FILTER_OPTION],
    keyword: [DEFAULT_FILTER_OPTION],
  });

  const [selectedFilters, setSelectedFilters] = useState<any>([]);
  const [apply, setApply] = useState<boolean>(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const filterTitles = [
    'field_side_of_support_relationsh',
    'field_type_of_actor',
    'field_conflict_related_activity',
    'field_category_of_support',
    'field_protection_concern',
    'field_area_of_practical_measure',
    'title',
  ];

  const t = useTranslation();
  const {
    applyFilters,
    practicalQuestions,
    dispatch,
  } = usePracticalQuestions();

  useEffect(() => {
    getAllFilters().then((filters: AllFilters) => setFiltersAvailable(filters));
  }, [setFiltersAvailable]);

  useEffect(() => {
    const sideOfRelationshipFilter =
      filtersAvailable.sideOfRelationship?.find(
        (o) =>
          o?.value !== 0 &&
          o?.value ===
            practicalQuestions.filters?.field_side_of_support_relationsh
      )?.description || '';

    const actorFilter =
      filtersAvailable.actor?.find(
        (o) =>
          o?.value !== 0 &&
          o?.value === practicalQuestions.filters?.field_type_of_actor
      )?.description || '';

    const activitiesFilter =
      filtersAvailable.activities?.find(
        (o) =>
          o?.value !== 0 &&
          o?.value ===
            practicalQuestions.filters?.field_conflict_related_activity
      )?.description || '';

    const supportFilter =
      filtersAvailable.support?.find(
        (o) =>
          o?.value !== 0 &&
          o?.value === practicalQuestions.filters?.field_category_of_support
      )?.description || '';

    const protectionConcernFilter =
      filtersAvailable.protectionConcern?.find(
        (o) =>
          o?.value !== 0 &&
          o?.value === practicalQuestions.filters?.field_protection_concern
      )?.description || '';

    const areaOfPracticalMeasuresFilter =
      filtersAvailable.areaOfPracticalMeasures?.find(
        (o) =>
          o?.value !== 0 &&
          o?.value ===
            practicalQuestions.filters?.field_area_of_practical_measure
      )?.description || '';

    const keywordFilter =
      practicalQuestions.filters?.title !== ''
        ? practicalQuestions.filters?.title
        : '';

    setSelectedFilters([
      sideOfRelationshipFilter,
      actorFilter,
      activitiesFilter,
      supportFilter,
      protectionConcernFilter,
      areaOfPracticalMeasuresFilter,
      keywordFilter,
    ]);

    if (apply) {
      applyFilters();
    }
  }, [
    apply,
    applyFilters,
    filtersAvailable.activities,
    filtersAvailable.actor,
    filtersAvailable.areaOfPracticalMeasures,
    filtersAvailable.protectionConcern,
    filtersAvailable.sideOfRelationship,
    filtersAvailable.support,
    practicalQuestions.filters?.field_area_of_practical_measure,
    practicalQuestions.filters?.field_category_of_support,
    practicalQuestions.filters?.field_conflict_related_activity,
    practicalQuestions.filters?.field_protection_concern,
    practicalQuestions.filters?.field_side_of_support_relationsh,
    practicalQuestions.filters?.field_type_of_actor,
    practicalQuestions.filters?.title,
  ]);

  const handleSelectValue = useCallback(
    ({ name, value }: any, apply: boolean = false) => {
      dispatch(update({ filters: { [name]: parseInt(value) } }));
      setApply(apply);
    },
    [dispatch]
  );

  const handleValue = useCallback(
    ({ name, value }: any, apply: boolean = false) => {
      dispatch(update({ filters: { [name]: value } }));
      setApply(apply);
    },
    [dispatch]
  );

  const removeFilter = useCallback(
    (index: number) => {
      selectedFilters[index] = '';
      handleSelectValue({ name: filterTitles[index], value: 0 }, true);
    },
    [filterTitles, handleSelectValue, selectedFilters]
  );

  const [expand, setExpand] = useState(false);
  const handlerToggleExpand = useCallback(() => {
    setExpand(!expand);
  }, [expand]);

  const stylesCN = useModifier('practical-questions-filter', '', {
    'practical-questions-filter--expanded': expand,
  });

  const clearAllFilters = useCallback(() => {
    filterTitles.map((filter: any, index: number) => [removeFilter(index)]);
  }, [filterTitles, removeFilter]);

  return (
    <div className={stylesCN}>
      <div className="practical-questions-filter__header">
        <div className="practical-questions-filter__header-title">
          <Button
            label={
              <div className="practical-questions-filter__header-title">
                <span>{t('practical-question.filters.filter-by')}</span>
                <div className="practical-questions-filter__title--inline practical-questions-filter__hide-desktop">
                  <Icon icon={expand ? Icons.ChevronUp : Icons.ChevronDown} />
                </div>
              </div>
            }
            onClick={handlerToggleExpand}
            modifier="transparent"
          />
        </div>

        <div className="practical-questions-filter__content_action desktop">
          <Button
            label={t('practical-question.filters.apply')}
            modifier="primary"
            onClick={applyFilters}
          />

          <Button
            label={t('practical-question.filters.clear')}
            modifier="primary"
            onClick={clearAllFilters}
          />
        </div>

        <div className="practical-questions-filter__header-extra practical-questions-filter__hide-desktop">
          <i>
            {t('practical-question.filters.showing')}{' '}
            <b>{practicalQuestions.total}</b>{' '}
            {t('practical-question.filters.results')}
          </i>
        </div>
      </div>
      <div className="columns practical-questions-filter__content">
        <div className="column col-12 col-sm-10 practical-questions-filter__content_item">
          <div className="columns">
            <PracticalQuestionsFilterItem
              options={filtersAvailable.sideOfRelationship}
              name="field_side_of_support_relationsh"
              label={t('practical-question.filters.side-of-relationship')}
              value={
                practicalQuestions.filters.field_side_of_support_relationsh ||
                DEFAULT_FILTER_OPTION.value
              }
              handleSelectValue={handleSelectValue}
            />

            <PracticalQuestionsFilterItem
              options={filtersAvailable.actor}
              name="field_type_of_actor"
              label={t('practical-question.filters.actor')}
              value={
                practicalQuestions.filters.field_type_of_actor ||
                DEFAULT_FILTER_OPTION.value
              }
              handleSelectValue={handleSelectValue}
            />

            <PracticalQuestionsFilterItem
              options={filtersAvailable.activities}
              name="field_conflict_related_activity"
              label={t('practical-question.filters.activities')}
              value={
                practicalQuestions.filters.field_conflict_related_activity ||
                DEFAULT_FILTER_OPTION.value
              }
              handleSelectValue={handleSelectValue}
            />

            <PracticalQuestionsFilterItem
              options={filtersAvailable.support}
              name="field_category_of_support"
              label={t('practical-question.filters.support')}
              value={
                practicalQuestions.filters.field_category_of_support ||
                DEFAULT_FILTER_OPTION.value
              }
              handleSelectValue={handleSelectValue}
            />

            <PracticalQuestionsFilterItem
              options={filtersAvailable.protectionConcern}
              name="field_protection_concern"
              label={t('practical-question.filters.protection-concern')}
              value={
                practicalQuestions.filters.field_protection_concern ||
                DEFAULT_FILTER_OPTION.value
              }
              handleSelectValue={handleSelectValue}
            />

            <PracticalQuestionsFilterItem
              options={filtersAvailable.areaOfPracticalMeasures}
              name="field_area_of_practical_measure"
              label={t('practical-question.filters.area-of-practical-measures')}
              value={
                practicalQuestions.filters.field_area_of_practical_measure ||
                DEFAULT_FILTER_OPTION.value
              }
              handleSelectValue={handleSelectValue}
            />
          </div>
        </div>
        <div className="column col-12 col-sm-10 col-md-2 col-bg-12">
          <div className="column col-12 col-md-10 practical-questions-filter-item">
            <Field
              type="text"
              name="title"
              value={
                practicalQuestions.filters.title === 0
                  ? ''
                  : practicalQuestions.filters.title
              }
              label={t('practical-question.filters.keyword')}
              onChange={handleValue}
              placeholder="Keyword"
            />
          </div>
        </div>
        <div className="column col-12 col-md-1 practical-questions-filter__content_action mobile">
          <Button
            label={t('practical-question.filters.apply')}
            modifier="primary"
            onClick={applyFilters}
          />
        </div>

        <div className="column col-12 col-md-1 practical-questions-filter__content_action mobile">
          <Button
            label={t('practical-question.filters.clear')}
            modifier="primary"
            onClick={clearAllFilters}
          />
        </div>
      </div>
      <div className="practical-questions-filter__selected-filters practical-questions-filter__hide-desktop">
        {selectedFilters.map((filter: any, index: number) =>
          filter ? (
            <div
              key={index}
              className="practical-questions-filter__selected-filters-content"
            >
              {filter}
              <span onClick={() => removeFilter(index)}>
                <Icon icon={Icons.Close} />
              </span>
            </div>
          ) : null
        )}
      </div>
    </div>
  );
};

export { PracticalQuestionsFilter };
