import { RestApi } from '../../../components';
import { JsonApiParsedResponse } from '../models/jsonApiParsed.response';
import { Taxonomy } from '../../../components/component-loader/models/taxonomy';

export const createPracticalQuestionsAPI = ({ GET }: RestApi) => {
  const getTaxonomy = (type: Taxonomy) => {
    return GET(
      `/jsonapi/taxonomy_term/${type}?fields[taxonomy_term--${type}]=drupal_internal__tid,name`
    );
  };

  const getQuestionResults = (
    langCode: string,
    query: any,
    offset: number,
    page: number
  ): Promise<JsonApiParsedResponse> => {
    return GET(
      `/${langCode}/jsonapi/node/practical_questions?include=field_area_of_practical_measure,\
field_category_of_support,field_protection_concern,field_area_of_practical_measure.field_detail_page,\
field_category_of_support.field_detail_page,field_protection_concern.field_detail_page&\
fields[node--landing_page]=path,field_path&page[limit]=${page}&page[offset]=${offset}&${query}&\
sort[highlight][path]=field_highlight&sort[highlight][direction]=DESC`
    );
  };

  return {
    getTaxonomy,
    getQuestionResults,
  };
};
