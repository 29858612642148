function createStore(namespace = 'STORE') {
  const ns = (key: string) => `${namespace}/${key}`;

  const get = (key: string) => {
    try {
      const value = sessionStorage[ns(key)];
      if (value) {
        // console.log('store.get', { key, value });
        return JSON.parse(value).value;
      }
    } catch (ex) {
      /* DO NOTHING */
    }
  };

  const set = (key: string, value: any) => {
    try {
      console.log('store.set', { key, value });
      sessionStorage[ns(key)] = JSON.stringify({ value });
    } catch (ex) {
      /* DO NOTHING */
    }
  };

  return {
    get,
    set,
  };
}

const store = createStore();

export { store };
