import { useCallback } from 'react';
import { FE_SRI_LNDO_SITE } from '../../../../config-envs';

const useUrl = () => {
  const isDev = useCallback(() => {
    return process.env.NODE_ENV === 'development';
  }, []);

  const getUrl = useCallback(
    (uri = '') => {
      return uri.replace(
        'public://',
        `${isDev() ? FE_SRI_LNDO_SITE : ''}/assets/`
      );
    },
    [isDev]
  );

  const getImageUrlFromText = useCallback(
    (uri = '') => {
      if (isDev()) {
        return uri.replaceAll('/assets', `${FE_SRI_LNDO_SITE}/assets`);
      }
      return uri;
    },
    [isDev]
  );

  return {
    getUrl,
    getImageUrlFromText,
  };
};

export { useUrl };
